import React, { createContext, useContext, useState, useReducer } from "react";

export const PageLoadingContext = createContext({});

export const PageLoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
      <PageLoadingContext.Provider value={{isLoading, setIsLoading}}>
        { children }
      </PageLoadingContext.Provider>
    )
  }
  
  export function usePageLoading() {
    return useContext(PageLoadingContext);
  }