const image_url_path = "./assets/MotifLineDrawing/european_object";

export const EuropeanObject = [
    {
        name: "bag",
        sub_category: "",
        image:`${image_url_path}/european_object_bag.svg`,
    },
    {
        name: "clothing",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_clothing.svg",
    },
    {
        name: "gun",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_gun.svg",
    },
    {
        name: "hat",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_hat.svg",
    },
    {
        name: "horse related item",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_horse_related_item.svg",
    },
    // {
    //     name: "knife",
    //     sub_category: "",
    //     image:"./assets/MotifLineDrawing/european_object/european_object_knife.svg",
    // },
    {
        name: "letters of alphabet",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_letters_of_alphabet.svg",
    },
    // {
    //     name: "mug",
    //     sub_category: "",
    //     image:"./assets/MotifLineDrawing/european_object/european_object_mug.svg",
    // },
    
    {
        name: "number",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_number.svg",
    },
    {
        name: "steel axe",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_steel_axe.svg",
    },
    {
        name: "pipe",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_pipe.svg",
    },
    {
        name: "boat",
        sub_category: "",
        image:"./assets/MotifLineDrawing/european_object/european_object_boat.svg",
    },
]