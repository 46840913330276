import React, {useEffect, useState} from 'react';
import { Page, Navbar, NavTitle, NavLeft, List, ListItem, Block, BlockTitle, Button, useStore, Card, CardContent, CardHeader, CardFooter, Link, Row, Col, f7 } from 'framework7-react';
import { db } from "../db";
import { getCacheKey } from '../helper/getCacheKey';

const CollectionListImage = (props) => {

    const [image_object, set_image_object] = useState({});

    // Get image data
    useEffect(() => {
        const fetchObject = async () =>{
            try {
                // Get image meta data
                let newImage = await db.images.get(props.image.id);

                let key = getCacheKey(newImage?.thumbnail)
                // Get image object
                const new_image_object = await db.image_cache.get(key);
                // Create object url
                const image_url = URL.createObjectURL(new_image_object.image);
                // Set state hook
                set_image_object(image_url)
                return image_url
            } catch (e) {
                console.error("Issue encountered generating object URL from image data", e)
            }
        }

       const url_object = fetchObject();
        // Cleanup function when effect is unmounted, revoke object URL
        // With framework7 setup to save previous page in DOM, this will occur after two navigations
        return () => url_object.then((value) => {URL.revokeObjectURL(value); console.log('Revoked thumbnail object url')});
    }, []);

    return(
            <img
            className="collectionsImagesThumbnail"
            slot="media"
            src={image_object}
            width="80"
            crossOrigin="anonymous"
            />
    );
}

export default CollectionListImage;