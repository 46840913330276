import React, { useEffect, useState, useRef } from "react";
import Annotation from "react-image-annotation";
import AnnotationPrev from "react-image-annotation";
import { RectangleSelector } from "react-image-annotation/lib/selectors";
import { v4 as uuidv4 } from "uuid";
import {
  Page,
  Link,
  Navbar,
  List,
  ListItem,
  NavRight,
  NavLeft,
  NavTitle,
  Icon,
  SwipeoutActions,
  SwipeoutButton,
  Block,
  Button,
  Panel,
  View,
} from "framework7-react";
import { useAnnotation } from "../../hooks/useAnnotation";
import { Dialog } from "../Dialogs";
import {
  ClearAllAnnotationsDialog,
  DeleteAnnotationFromMenuDialog,
} from "../Dialogs/dialogsContent";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../db";
import { MyEditor } from "./MyEditor";
import "./AnnotationEditor.scss";
import axios from "axios";
import { getCacheKey } from "../../helper/getCacheKey";

const AnnotationEditor = (props) => {
  //Hooks for global context, currently only editMode is using in this context.
  const { annotationState, setAnnotationState } = useAnnotation();

  const [annotation, setAnnotation] = useState({});
  const [activeAnnotations, setactiveAnnotations] = useState([]);
  const [activeAnnotationsPreview, setactiveAnnotationsPreview] = useState([]);
  const [currentImagePrev, setCurrentImagePrev] = useState("");
  const [toggleShowHide, setToggleShowHide] = useState(false);
  const [isOnChange, setIsOnChange] = useState(false);

  const [image, setImage] = useState("");
  const [imageMeta, setImageMeta] = useState({});
  const [previewImageAreaHeight, setPreviewImageAreaHeight] = useState(0);
  const [previewImageTitleHeight, setPreviewImageTitleHeight] = useState(0);
  const previewImageArea = useRef(null);
  const previewImageTitle = useRef(null);

  const maxImagePreviewHeight = 420; //set preview image height to 420px

  // Get and watch annotations from indexedDB
  const annotations = useLiveQuery(async () => {
    return await db.annotations
      .where({ collection_id: props.collection_id, image_id: props.id })
      .toArray();
  });

  // Get current collection from the indexedDB
  const collection = useLiveQuery(async () => {
    return await db.collections.get(props.collection_id);
  });

  // Get image data
  useEffect(() => {
    const fetchObject = async () =>{
      try {
          // Get image meta data
          let newImage = await db.images.get(props.id);
          let key = getCacheKey(newImage?.image_object)
          // Get image object
          const new_image_object = await db.image_cache.get(key);
          // Create object url
          const image_url = URL.createObjectURL(new_image_object.image);
          // Set state hook
          setImage(image_url);
          return image_url
      } catch (e) {
          console.error("Issue encountered generating object URL from image data", e)
      }
  }

    const url_object = fetchObject();
    // Cleanup function when effect is unmounted, revoke object URL
    // With framework7 setup to save previous page in DOM, this will occur after two navigations
    return () => url_object.then((value) => {URL.revokeObjectURL(value); console.log('Revoked object url')});
  }, []);
  

  const getImageMetaObject = (img) => {
    /**
     * Get image meta object
     * @param {HTMLImageElement} img - image element
     * @returns {Object} - image meta object
     */
    const imageSizeRatio = (img.naturalWidth / img.naturalHeight).toFixed(2);
    const imgOrientation = imageSizeRatio < 1 ? "portrait" : "landscape";

    return {
      orientation: imgOrientation,
      width: img.naturalWidth,
      height: img.naturalHeight,
      ratio: imageSizeRatio,
    };
  };

  function getImageMeta(url) {
    /**
     * getImageMeta()
     * @param url
     * @returns {Promise<HTMLImageElement>}
     */
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  }

  useEffect(async () => {
    /**
     * Get the image meta data and store in imageMeta state
     */
    if (image == "") return;
    try{
      const imgMeta = await getImageMeta(image);
      const imgMetaObj = await getImageMetaObject(imgMeta);
      setImageMeta(imgMetaObj);
      console.log('Metadata successfully fetched');
    }catch(e){
      console.warn("Error getting image for metadata")
    }

  }, [image]);

  useEffect(() => {
    /**
     * Dynamically set the height of the preview image area
     */
    if (previewImageArea.current == null || previewImageTitle.current == null)
      return;
    if (
      previewImageArea.current.clientHeight == 0 ||
      previewImageTitle.current.clientHeight == 0
    )
      return;

    setPreviewImageAreaHeight(previewImageArea.current.clientHeight);
    setPreviewImageTitleHeight(previewImageTitle.current.clientHeight);
    console.log(
      "previewImageAreaHeight",
      previewImageAreaHeight,
      previewImageTitleHeight
    );
  }, [
    previewImageArea?.current?.clientHeight,
    previewImageTitle?.current?.clientHeight,
  ]);

  const annotationHandler = (action) => {
    /**
     * annotation: {} is a temporary placeholder for the selected annotation
     * Always empty the annotation object e.g. annotation: {}, after save/update/cancel/submit to end the renderEditor process
     */
    switch (action.type) {
      case "editAnnotation":
        console.log("editAnnotation");
        setactiveAnnotations([]);
        setAnnotation(action.annotation);
        break;
      case "cancelAnnotation":
        console.log('cancelAnnotation', annotation)
        setactiveAnnotations([]);
        setAnnotation({});
        console.log('cancelAnnotation 2', annotation)
        break;
      case "submitAnnotation":
        console.log('submitAnnotation', annotation, action.annotation.id, action.annotation)
        db.transaction("rw", db.annotations, db.images, async () => {
          const annotationsObj = {
            id: uuidv4(),
            session_id: "1234",
            collection_id: props.collection_id,
            image_id: props.id,
            data: action.annotation.data,
            geometry: action.annotation.geometry,
            selection: action.annotation.selection,
          };

          const annotationsRes = await db.annotations.add(annotationsObj);
          console.log("submitAnnotation", action);
        })
          .then(() => {
            console.log("submitAnnotation is successful");

            setAnnotation({}); //Exit the popup editor mode
          })
          .catch((err) => {
            console.error(err.stack);
          });

        break;
      case "updateActiveAnnotation":
        setAnnotation({});
        console.log('updateActiveAnnotation', annotation, action.annotation.id, action.annotation)
        db.annotations.update(action.annotation.id, action.annotation);
        break;
      case "deleteAnnotation":
        console.log("deleteAnnotation", action);
        const deleteAnnotation = () => {
          db.annotations.delete(action.annotation.id);
        };
        Dialog(DeleteAnnotationFromMenuDialog, () => deleteAnnotation(), `Confirm_DeleteAnnotation_${action.annotation.id}`, );
        //db.annotations.delete(action.annotation.id)
        break;
      case "getPreviewImage":
        console.log("panel reducer id", props.id, action.imageId);
        const updatePrevImage = () => {
          const imageSrc = db.images.get(action.imageId);
          setCurrentImagePrev(imageSrc.image_object);
          console.log("panel reducer src", imageSrc, currentImagePrev);
        };
        updatePrevImage();
        break;
      case "listItemMouseDown":
        //console.log("listItemMouseDown",action)
        const activeId = activeAnnotationsPreview.includes(action.id);
        if (!activeId) {
          setactiveAnnotationsPreview([...activeAnnotationsPreview, action.id]);
        } else {
          const index = activeAnnotationsPreview.indexOf(action.id);
          setactiveAnnotationsPreview([
            ...activeAnnotationsPreview.slice(0, index),
            ...activeAnnotationsPreview.slice(index + 1),
          ]);
        }
        break;
      case "listItemMouseOut":
        const index = activeAnnotationsPreview.indexOf(action.id);
        setactiveAnnotationsPreview([
          ...activeAnnotationsPreview.slice(0, index),
          ...activeAnnotationsPreview.slice(index + 1),
        ]);
        break;

      case "clearActiveAnnotationsPreview":
        setactiveAnnotationsPreview([]);
        break;
      case "clearAll":
        const clearAllaction = () => {
          // Grab annotation id's
          const annotationArr = [];
          annotations.forEach((annotation) => {
            annotationArr.push(annotation.id);
          });
          // Bulk delete from indexedDB
          db.annotations.bulkDelete(annotationArr);
          setAnnotation({});
          setactiveAnnotations([]);
        };

        Dialog(ClearAllAnnotationsDialog, () => clearAllaction(), "Confirm_ClearAll");
        break;
      default:
        throw new Error();
    }
  };

  const renderOverlay = () => {
    return (
      <div
        style={{
          background: "rgba(0, 0, 0, 0.3)",
          color: "white",
          padding: 5,
          pointerEvents: "none",
          position: "absolute",
          top: 5,
          left: 5,
        }}
      >
        Preview
      </div>
    );
  };

  const selectionModalStyle = {
    background: "white",
    color: "#333",
    padding: 10,
    position: "absolute",
    fontSize: 14,
  }

  const displaySelectionModalTopLeft = (geometry) =>  (
    {...selectionModalStyle,
    left: `${geometry.x}%`,
    bottom: `${100-geometry.y}%`,
  })

  const displaySelectionModalBottomLeft = (geometry) => (
    {...selectionModalStyle,
    left: `${geometry.x}%`,
    top: `${geometry.y + geometry.height}%`,}
  )

  const renderContent = ({ annotation }) => {
    const { geometry } = annotation;
   
    // Get the offsetHeight of annotation selection modal 
    const elementHeight = document.getElementById(`MainImage_renderContent_${annotation.id}`)?.offsetHeight
    const estimatedModalPosition = `${100 + geometry.y + geometry.height}`


    return (
      !isOnChange && (
        <div
          id={`MainImage_renderContent_${annotation.id}`}
          className="annotationRenderContent"
          key={annotation.id}
          // if the estimatedModalPosition is greater than the element height, then display the selection modal top left of the annotation else bottom left.
          style={estimatedModalPosition > elementHeight ? displaySelectionModalTopLeft(geometry) : displaySelectionModalBottomLeft(geometry)}
        >
          <p>{annotation.data && annotation.data.text}</p>
          <img src={annotation.data.image} alt={annotation.data.text} />
          <Button
            id={`MainImage_renderContent_Edit_${annotation.id}}`}
            className="EditButton"
            large
            small
            fill
            onClick={() => {
              setAnnotationState({ editMode: true });
              annotationHandler({
                type: "editAnnotation",
                annotation: annotation,
              });
            }}
          >
            edit
          </Button>
          <Button
            id={`MainImage_renderContent_Delete_${annotation.id}}`}
            className="DeleteButton"
            large
            small
            fill
            onClick={() =>
              annotationHandler({
                type: "deleteAnnotation",
                annotation: annotation,
              })
            }
          >
            delete
          </Button>
        </div>
      )
    );
  };

  const renderContentPreview = ({ annotation }) => {
    const { geometry } = annotation;
    console.log("renderContentPreview", annotation);

    return (
      <div
        id={`PreviewImage_RenderAnnotation_` + annotation.id}
        className="annotationRenderContent"
        key={annotation.id}
        style={{
          // maxWidth: '10vw',
          // maxHeight: '10vh',
          background: "white",
          color: "#333",
          padding: 10,
          position: "absolute",
          fontSize: 14,
          left: `${geometry.x}%`,
          top: `${geometry.y + geometry.height}%`,
        }}
      >
        <p>{annotation.data && annotation.data.text}</p>
        <img src={annotation.data.image} alt={annotation.data.text} />
      </div>
    );
  };

  const activeAnnotationComparator = (a, b) => {
    console.log("activeAnnotationComparator", activeAnnotations);
    return a.id === b;
  };

  const activeAnnotationComparatorPreview = (a, b) => {
    return a.id === b;
  };

  const handleChange = (a) => {
    annotationHandler({ type: "editAnnotation", annotation: a });
  };

  const handleSubmit = (a) => {
    console.log("handleSubmit", a);

    if (annotationState.editMode) {
      annotationHandler({ type: "updateActiveAnnotation", annotation: a });
    } else {
      annotationHandler({ type: "submitAnnotation", annotation: a });
    }
  };

  const onMouseDown = (id) => (e) => {
    console.log("mouseover", id);
    annotationHandler({ type: "listItemMouseDown", id });
    setToggleShowHide(!toggleShowHide);
  };

  // const onMouseOut = (id) => e => {
  //   const index = activeAnnotations.indexOf(id)
  //   annotationHandler({ type: "listItemMouseOut", id })
  // }

  const renderAnnotationList = () => {
    return (
      <div>
        <List className="AnnotationMenuList" noHairlines noHairlinesBetween>
          {console.log(annotations)}
          {annotations.length == 0 && (
            <p style={{ margin: "1em" }}>No annotation created.</p>
          )}
          {annotations.map((a, i) => {
            return (
              <ListItem
                swipeout
                title={`Annotation ${i + 1} - ${a.data.text}`}
                subtitle={a.data.text}
                key={i}
                id={`AnnotationList_Item_` + a.data.id}
              >
                {/* <div
                  onMouseOver={onMouseOver(a.data.id)}
                  onMouseOut={onMouseOut(a.data.id)}
                > */}
                <img slot="media" src={a.data.image} width="44" />
                <SwipeoutActions right>
                  <SwipeoutButton id={`ShowHideAnnotation_` + i} onClick={onMouseDown(a.id)}>
                    {toggleShowHide ? "Hide" : "Show"}
                  </SwipeoutButton>
                  <SwipeoutButton
                    id={`AnnotationList_DeleteAnnotation_` + i }
                    onClick={() =>
                      annotationHandler({
                        type: "deleteAnnotation",
                        annotation: a,
                      })
                    }
                  >
                    Delete
                  </SwipeoutButton>
                </SwipeoutActions>
                {/* </div> */}
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  // Get index of image in the current collection
  const getImageIndex = () => {
    const index = collection?.images_id?.indexOf(props.id);
    return index !== -1 ? index : null;
  };

  const displayCurrentImageIndex = () => {
    const index = getImageIndex() + 1;
    return index;
  };

  // Returns next index in collection if it exists, otherwise returns undefined
  const checkNextIndex = (index) => {
    const nextIndex = collection?.images_id[index + 1];
    return nextIndex;
  };

  // Returns next index in collection if it exists, otherwise returns undefined
  const checkPrevIndex = (index) => {
    const nextIndex = collection?.images_id[index - 1];
    return nextIndex;
  };

  // Renders next image button
  const nextImageButton = (index) => {
    return (
      <div className="nextImageButton">
        <Link
          id="nextImageButton"
          href={`/annotation/${props.collection_id}/image/${
            collection?.images_id[index + 1]
          }/`}
        >
          <Icon
            ios="f7:chevron_right"
            aurora="f7:chevron_right"
            md="material:ChevronRight"
          ></Icon>
        </Link>
      </div>
    );
  };

  // Renders previous image button
  const prevImageButton = (index) => {
    return (
      <div className="prevImageButton">
        <Link
          id="prevImageButton"
          href={`/annotation/${props.collection_id}/image/${
            collection?.images_id[index - 1]
          }/`}
        >
          <Icon
            ios="f7:chevron_left"
            aurora="f7:chevron_left"
            md="material:ChevronLeft"
          ></Icon>
        </Link>
      </div>
    );
  };

  const renderNavPlaceholder = () => {
    return (
      <div className="navPlaceholder">
        <Icon
          ios="f7:chevron_left"
          aurora="f7:chevron_left"
          md="material:ChevronLeft"
        ></Icon>
      </div>
    );
  };

  // Current index of the image within the collection
  const currentIndex = getImageIndex();

  const renderPreviewImage = () => {
    return (
      <div
        id="PreviewImage"
        className="previewImageArea"
        ref={previewImageArea}
      >
        {console.log("imgMeta", imageMeta, imageMeta?.orientation)}
        <div
          className="previewImageWrapper"
          style={{
            width:
              imageMeta?.orientation == "portrait"
                ? `${imageMeta?.ratio * maxImagePreviewHeight}px`
                : "100%",
          }}
        >
          {image && (
            <AnnotationPrev
              key={`prevAnnotation ${props.id}`}
              src={image}
              alt={"Two pebbles anthropomorphized holding hands"}
              activeAnnotationComparator={activeAnnotationComparatorPreview}
              activeAnnotations={activeAnnotationsPreview}
              annotations={annotations ? annotations : []}
              //annotations={state.annotations}
              allowTouch
              type={RectangleSelector.TYPE}
              value={annotation}
              renderContent={renderContentPreview}
              renderOverlay={renderOverlay}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link
            id="TopNavBack"
            icon={"icon icon-back"}
            href={`/collection-images/${props.collection_id}`}
          >
            Back
          </Link>
        </NavLeft>
        <NavTitle
          title="Edit Annotations"
          subtitle={`${
            collection?.title
          } - Image ${displayCurrentImageIndex()} of ${
            collection?.images_id?.length
          } `}
        />
        <NavRight>
          {/* disable panel open when popup is open */}
          {Object.keys(annotation).length === 0 ? (
            <Link id="TopNavEdit" panelOpen="right">
              Edit
            </Link>
          ) : (
            <p>Edit</p>
          )}
        </NavRight>
      </Navbar>

      {/* Right panel with reveal effect*/}
      <Panel
        key={props.id}
        className="annotationRightPanel"
        right
        reveal
        backdrop={true}
        onPanelOpen={() => {
          console.log("render panel", props.id, currentIndex);
          annotationHandler({ type: "getPreviewImage" });
        }}
        onPanelClosed={() => {
          annotationHandler({ type: "clearActiveAnnotationsPreview" });
        }}
      >
        <View browserHistory={false}>
          <Page name={`right-panel-${props.id}`} pageContent={false}>
            <Navbar title="Annotations menu" />
            {/* framework7 properties - add slot="fixed" to fix the position of the HTML element */}
            <div slot="fixed">{image != "" && renderPreviewImage()}</div>

            <div className="previewImageTitle" ref={previewImageTitle}>
              <h1>Annotations</h1>
              <Button
                id="AnnotationList_ClearAll"
                large
                small
                fill
                className="ClearAllButton"
                onClick={() => {
                  annotationHandler({ type: "clearAll" });
                }}
              >
                Clear all
              </Button>
            </div>
            {console.log(
              "previewImageArea.current",
              previewImageAreaHeight,
              previewImageTitleHeight
            )}
            <div
              style={{
                overflow: "auto",
                height:
                  previewImageAreaHeight > 0
                    ? `calc(100% - ${previewImageAreaHeight}px - ${previewImageTitleHeight}px)`
                    : "100%",
              }}
            >
              {annotations ? renderAnnotationList() : "No annotation created"}
            </div>
          </Page>
        </View>
      </Panel>

      <Block className="flex-vertical-center-item no-margin no-padding height-100 background-dark-grey overflow-hidden">
        <div className="canvasWrapper">
          {
            // Render previous Image button if there is an image next in the collection
            checkPrevIndex(currentIndex)
              ? prevImageButton(currentIndex)
              : renderNavPlaceholder()
          }
          {console.log("image main", image)}
          {image && (
            <div id={`WalshCollection_${props.id}`}>
              <Annotation
                id="MainImage"
                key={`annotation ${props.id}`}
                // Fallback to image url if image object not truthy
                src={image}
                alt="Two pebbles anthropomorphized holding hands"
                activeAnnotationComparator={activeAnnotationComparator}
                activeAnnotations={activeAnnotations}
                annotations={annotations ? annotations : []}
                allowTouch={true}
                type={RectangleSelector.TYPE}
                value={annotation}
                renderEditor={({ annotation, onChange, onSubmit }) => (
                  <MyEditor
                    id={`AnnotationEditor_${props.id}`}
                    image={image}
                    annotation={annotation}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onCancel={() =>
                      annotationHandler({ type: "cancelAnnotation" })
                    }
                  />
                )}
                renderContent={renderContent}
                onChange={(a) => handleChange(a)}
                onSubmit={(a) => handleSubmit(a)}
              />
            </div>
          )}
          {
            // Render next Image button if there is an image next in the collection
            checkNextIndex(currentIndex)
              ? nextImageButton(currentIndex)
              : renderNavPlaceholder()
          }
        </div>
      </Block>
    </Page>
  );
};

export default AnnotationEditor;
