import React, {useEffect, useState} from 'react';
import { Page, Navbar, List, ListItem, Block, Button, useStore, Card, CardContent, CardHeader, CardFooter, Link, Row, Col, f7, Icon } from 'framework7-react';
import store from '../js/store'
//import PageviewIcon from '@mui/icons-material/Pageview';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import "../css/cards.css";
import { PageWrapper } from "./PageWrapper";
import PinCodeInput from '../components/PinCodeInput';
import "./CollectionsPage.scss"

const CollectionsPage = (props) => {
  const { f7route, f7router } = props;

  const [errorMsg, setErrorMsg] = useState('');
  const [isToggle, setIsToggle] = useState(false);
  const [showItem, setShowItem] = useState(null); //show hide PIN input 

  const collections = useLiveQuery(async () => {
    try {    
      return db.collections.toArray() } 
    catch (error) {
      console.error("Error from Collection page: ", error)
      setErrorMsg(error.toString());
    }
  })
  
  const navbar = (
    <Navbar
        id="CollectionNavBar"
        title="Digitising the Deep Past"
        subtitle="Collections"
      />
  )
  
  const renderBrowseImage = (collection) => {
      if(collection?.pin == "" ) 
      return ( 
        <>
        <Icon id="BrowseImage" className="BrowseImageLinks" material="pageview"></Icon> 
        <span>Browse images</span>
        </>
      )
      
      return (
        <>
        <Icon id="BrowseImage" className="BrowseImageLinks" material="lock_fill"></Icon> 
        <span>Browse images</span>
        </>
      )
  }

  const renderLink = (collection) => {
    if (collection.pin != "" && collection.pin != undefined) return "#";

    return `/collection-images/${collection.id}`;
  } 

  const renderText = (collection) => {
    return `${collection?.notes}`
 }

  return (
    <PageWrapper name="collections" navbar={navbar}>
      <Block className="CollectionPageContent">

        {/* List item display as Cards */}
          {errorMsg != "" && <p>{errorMsg}</p>}
          {collections?.length == 0 && <p>Currently no collections downloaded to the iPad.</p>}
          
          <List className="CollectionList" noHairlines noHairlinesBetween>
          {collections?.map((collection, index) => {
            {console.log("collectins note",collection.notes)}
            return (
              <ListItem
                id={`CollectionListItem_`+ index}
                className='CollectionListItem'
                title={collection.title}
                key={index}
                link={renderLink(collection)}
                
                text={collection.notes}
           
                onClick={()=>setShowItem( showItem === index ? null : index)}
                noChevron
              >
                <p className='CollectionNotes'>{collection?.notes}</p>
                <div className='CollectionBrowseImageWrapper'>{renderBrowseImage(collection)}</div>
                {collection.pin != "" && collection.pin != undefined &&
                  <>
                  { showItem === index && 
                  <PinCodeInput id="PinCodeInput" collectionPin={`${collection.pin}`} url={`/collection-images/${collection.id}`} f7router={f7router} /> }  
                  </>
                }
              </ListItem>
            );
          })}
          </List>
     
        </Block>
    </PageWrapper>
  );
}

export default CollectionsPage;
