import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import { Page, Navbar, NavTitle, NavLeft, List, ListItem, Block, BlockTitle, Button, useStore, Card, CardContent, CardHeader, CardFooter, Link, Row, Col, f7 } from 'framework7-react';

import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";
import { PageWrapper } from './PageWrapper';
import CollectionListImage from '../components/CollectionListImage';

const CollectionsImagesPage = (props) => {
    const { f7route, f7router } = props;

    const infiniteScrollSize = 5;
    const [collectionImages, setCollectionImages] = useState([]);
    const [shownImages, setShownImages]  = useState(infiniteScrollSize);

    const allowInfinite = useRef(true);

    const [showPreloader, setShowPreloader] = useState(true);

    // Onload get collection images and the associated annotatin counts
    useEffect(() => {
        const getAnnotations = async () => {
            const imageAnnotations = await db.transaction('r', db.images, db.annotations, db.collections, async () => {
                // Grab collection instead of looking up images that belong to collection based on id,
                // this prevents situation of image meta data being outdated or changed compared to
                // when collection was first downloaded
                const collection = await db.collections.get(parseInt(f7route.params.collectionId));

                const images = await db.images.bulkGet(collection.images_id);

                return Promise.all(images.map(async image => {
                    const annotationCount = await db.annotations.where({collection_id: parseInt(f7route.params.collectionId), image_id: image.id}).count()
                    return {...image, annotation_count: annotationCount}
                }))
            })
            setCollectionImages(imageAnnotations);
            if (imageAnnotations.length <= 5) {
                setShowPreloader(false);
                allowInfinite.current = false;
            }
        }
        getAnnotations();
        return () => {console.log("Collection page unmounted")}
    }, []);


    const collection = useLiveQuery(async () => {
        return await db.collections.get(parseInt(f7route.params.collectionId));
      })



    const loadMore = async () => {
        if (!allowInfinite.current) return;
        allowInfinite.current = false;

        if (collectionImages.length <= 5) {
            setShowPreloader(false);
            return
        }

        // Check if there are any more pages to load
        if(shownImages >= collectionImages.length){
          setShowPreloader(false);
          return;
        }

        setShownImages((current) => current + infiniteScrollSize);
        allowInfinite.current = true;
    };
    
    const navbar = (
        <Navbar sliding>
          <NavLeft>
            <Link id="TopNavBack" icon={"icon icon-back"} href={"/collection/"}>
              Back
            </Link>
          </NavLeft>
          <NavTitle title="Collections Images" subtitle="" />
        </Navbar>
      );

    return(
        <PageWrapper name="collectionsImages"
            infinite
            infiniteDistance={50}
            infinitePreloader={showPreloader}
            onInfinite={loadMore}
            navbar={navbar}>
             
             <Block> 
             <BlockTitle>{`${collection?.title}`}</BlockTitle>{/*  TO DO: add collection title to the api response object */}
               <p>{`Total images: ${collectionImages?.length}`}</p>
                <List mediaList>
                {collectionImages?.map((image, index) => {
                        if(index < shownImages){
                            return (
                                <ListItem
                                    id={`CollectionImageListItem_` + image.id}
                                    key={image.id}
                                    link={`/annotation/${f7route.params.collectionId}/image/${image.id}/`}
                                    title={`${collection?.title} ${index + 1}`}
                                    after=""
                                    subtitle={``}
                                    text={`Annotations created: ${image.annotation_count}`}
                                >
                                <CollectionListImage
                                    id={`CollectionListImage_` + image.id}
                                    imageIndexNum={index}
                                    image={image}
                                    collectionId={f7route.params.collectionId}
                                    className="collectionsImagesThumbnail"
                                    slot="media"
                                />
                                </ListItem>
                                )
                        }else{
                            return (null);
                        }
                    }
                    )}
                </List>
             </Block>
        </PageWrapper>
    )
}

export default CollectionsImagesPage;