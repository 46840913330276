const image_url_path = "./assets/MotifLineDrawing/flying_animal";

export const FlyingAnimal = [
  {
    name: "emu",
    sub_category: "bird",
    image: `${image_url_path}/flying_aninmal_bird_emu.svg`,
  },
  {
    name: "flying fox",
    sub_category: "",
    image: `${image_url_path}/flying_animal_flying_fox.svg`,
  },
  {
    name: "turkey",
    sub_category: "bird",
    image: `${image_url_path}/flying_animal_bird_turkey.svg`,
  },
  {
    name: "moth",
    sub_category: "",
    image: `${image_url_path}/flying_animal_flying_moth.svg`,
  },
  {
    name: "ibis",
    sub_category: "bird",
    image: `${image_url_path}/flying_animal_bird_ibis.svg`,
  },
  {
    name: "bees or sugarbag",
    sub_category: "",
    image: `${image_url_path}/flying_animal_bees_and_or_sugarbag.svg`,
  },

  // {
  //   name: "jabiru",
  //   sub_category: "bird",
  //   image:`${image_url_path}/flying_animal_bird_jabiru.svg`,
  // },

  // {
  //   name: "brolga",
  //   sub_category: "bird",
  //   image:`${image_url_path}/flying_animal_bird_brolga.svg`,
  // },
];
