import {
    f7,
  } from 'framework7-react';
  
  //cssClass - For UAT testing
  export const Dialog = (dialogContent, dialogAction, cssClass=" ") => {
    console.log('dialogAction', dialogAction)
  
    f7.dialog.create(
     {
       cssClass: cssClass,
       title: dialogContent.title,
       text: dialogContent.text,
       verticalButtons:true,
       buttons: [
        {
          text: dialogContent.buttonText,
          onClick: () => {
            
            const promise = new Promise(function (resolve, reject) {
              resolve();
            });
  
            promise
              .then(function () {
                return dialogAction()
              })
              .then(function () {
                console.log("collection deleted", dialogContent.responseMessage)
                if(dialogContent.responseMessage != undefined && dialogContent.responseMessage != ""){ 
                  f7.dialog.alert(dialogContent.responseMessage, ""); 
                }
              }).catch((err)=>{
                if (dialogContent.errorMessage){
                  f7.dialog.alert(dialogContent.errorMessage);
                }else{
                  f7.dialog.alert(err);
                }

              });
          }
        },
        {
          text: 'Cancel',
        },
       ]
     } 
    ).open();
  }