const image_url_path = "./assets/MotifLineDrawing/aboriginal_object";

export const AboriginalObject = [
      {
        name: "Boomerang",
        sub_category: "",
        image:`${image_url_path}/aboriginal_object_boomerang.svg`,
      },
      {
        name: "Shield",
        sub_category: "",
        image:`${image_url_path}/aborginal_object_shield.svg`,
      },
      {
        name: "Dilly bag",
        sub_category: "",
        image:`${image_url_path}/aboriginal_object_dilly_bag.svg`,
      },
      {
        name: "Axe",
        sub_category: "",
        image:`${image_url_path}/aboriginal_object_axe.svg`,
      },
      {
        name: "Belt",
        sub_category: "",
        image:`${image_url_path}/aboriginal_object_belt.svg`,
      },
      
      {
        name: "Digging stick",
        sub_category: "",
        image:`${image_url_path}/aboriginal_object_digging_stick.svg`,
      },
      // {
      //   name: "Fibre object",
      //   sub_category: "",
      //   image:`${image_url_path}/aboriginal_object_fibre_object.svg`,
      // },
      // {
      //   name: "Branch or fern",
      //   sub_category: "Head dress",
      //   image:`${image_url_path}/aboriginal_object_head-dress_branch_or_fern.svg`,
      // },
      // {
      //   name: "Enclosed rays",
      //   sub_category: "Head dress",
      //   image:`${image_url_path}/aboriginal_object_head-dress_enclosed_rays.svg`,
      // },
      // {
      //   name: "Long cap",
      //   sub_category: "Head dress",
      //   image:`${image_url_path}/aboriginal_object_head-dress_long_cap.svg`,
      // },
      // {
      //   name: "Ray or petal",
      //   sub_category: "Head dress",
      //   image:`${image_url_path}/aboriginal_object_head-dress_ray_or_petal.svg`,
      // },
      // {
      //   name: "Three pronged",
      //   sub_category: "Head dress",
      //   image:`${image_url_path}/aboriginal_object_head-dress_three_pronged.svg`,
      // },
      {
        name: "Pendant",
        sub_category: "",
        image:`${image_url_path}/aborginal_object_pendant.svg`,
      },
      
      {
        name: "Spearthrower",
        sub_category: "",
        image:`${image_url_path}/aborginal_object_spearthrower.svg`,
      },
      {
        name: "Spear simple",
        sub_category: "",
        image:`${image_url_path}/aborginal_object_spear_simple.svg`,
      },
      // {
      //   name: "Spear complex",
      //   sub_category: "",
      //   image:`${image_url_path}/aborginal_object_spear_complex.svg`,
      // },
    ]
  