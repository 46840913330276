import React, { useState } from 'react'
import {
  Page,
  Popup,
  Navbar,
  NavRight,
  NavLeft,
  NavTitle,
  Block,
  Row,
  Button,
} from 'framework7-react';
import { useAnnotation } from '../../hooks/useAnnotation';
import ImageCropPreview from '../ImageCropPreview'
import {
  AboriginalObject,
  EuropeanObject,
  FlyingAnimal,
  GeometricDesign,
  Human,
  LandBasedAnimal,
  Plants,
  SpiritCreationFigures,
  Tracks,
  WaterBasedAnimal,
  Other,
} from "../../assets/LineDrawingCategory/index";
import { toPascalCase } from '../../helper/toPascalCase';
import "./AnnotationEditor.scss"

export const MyEditor = (props) => {

  //props.onChangeEnd(); // Reset isOnChange to true after dragging to create annotation is sccuessfully created.
  const { annotationState, setAnnotationState } = useAnnotation();
  const { geometry } = props.annotation

  //Initialize the image object and get the metadata e.g.(width and height)
  const imgObject = new Image();
  imgObject.src = props.image;
  
  //convert the size unit from percentage to pixel (react annotation uses percentage)
  const xPosInPixel = imgObject.naturalWidth * geometry.x / 100
  const yPosInPixel = imgObject.naturalHeight * geometry.y / 100
  const widthInPixel = imgObject.naturalWidth * geometry.width / 100
  const heightInPixel = imgObject.naturalHeight * geometry.height / 100

  if (!geometry) return null
  console.log('MyEditor', geometry)
  
  /* Bug fix - prevent user drawing tiny annotation */
  if (widthInPixel < 20 || heightInPixel < 20) {
    props.onCancel();
    return null
  }
  
  const [popupOpened, setPopupOpened] = useState(true);
  const [activeSelectedItemIndex, setActiveSelectedItemIndex] = useState(-1);
  const [state, setState] = useState({
    selectedItem: null,
    selectedCategory: null
  });

  const cropArea = {
    x: xPosInPixel,
    y: yPosInPixel,
    width: widthInPixel,
    height: heightInPixel
  }
  console.log('cropArea', cropArea, imgObject, imgObject.naturalHeight)
  const closePopup = () => {
    console.log('on popup closed')
    
    setPopupOpened(false)
    
    if(state.selectedItem != null && state.selectedCategory != null) {
      console.log('popup submit')
      props.onChange({ ...props.annotation, data: { ...props.annotation.data, text: `${state.selectedCategory} - ${state.selectedItem.name}`, image: `${state.selectedItem.image == "" ? "./assets/image_placeholder.png": state.selectedItem.image}` } })
      props.onSubmit();
    } else {
      console.log('popup onCancel')
      props.onCancel();
    } 
    setAnnotationState({ editMode: false })
  }

  const toggleSelectable = (index, category, lineDrawingItem) => {
    setActiveSelectedItemIndex(index)
    setState({ selectedItem: lineDrawingItem, selectedCategory: category });
  };
  
  const [displayLineDrawingItems, setDisplayLineDrawingItems] = useState(false);
  const [currentCategory, SetCurrentCategory] = useState("");

  const categories = [
    "Aboriginal Object",
    "European Object",
    "Flying Animal",
    "Geometric Design",
    "Human",
    "Land-based Animal",
    "Plants",
    // "Spirit/Creation Figures", REMOVED
    "Tracks",
    "Water-based Animal",
    // "Other", REMOVED
  ];

  const checkLineDrawingCategory = (cat) => {
    switch(cat) {
      case "Aboriginal Object":
        return AboriginalObject;
        break;
      case "European Object":
        return EuropeanObject;
        break;
      case "Flying Animal":
        return FlyingAnimal;
        break;
      case "Geometric Design":
        return GeometricDesign;
        break;
      case "Human":
        return Human;
        break;
      case "Land-based Animal":
        return LandBasedAnimal;
        break;
      case "Water-based Animal":
        return WaterBasedAnimal;
        break;
      case "Plants":
        return Plants;
        break;
      case "Spirit/Creation Figures":
        return SpiritCreationFigures;
        break;
      case "Tracks":
        return Tracks;
        break;
      case "Other":
        return Other;
        break;
      default:
        return {}
    }
  }

  const displayLineDrawingIcons = (cat) => {
    const lineDrawingItems = checkLineDrawingCategory(cat)
    const categoryName = toPascalCase(cat)

    return (
      <>
        {lineDrawingItems?.map((item, index) => {
          return (
            <Button
              id={`LineDrawingItem_${categoryName}_${toPascalCase(item.name)}_${index}`}
              key={`${item.name} - ${index} `}
              onClick={() => toggleSelectable(index, cat, item)}
              className={index === activeSelectedItemIndex ? "LineDrawingItem active" : "LineDrawingItem"}
            >
              <div className="lineDrawingImage" style={{
                background: `transparent url(${item.image == "" ? "./assets/image_placeholder.png": item.image}) no-repeat center`,
              }}>
              </div>
              <p>{`${item.name}`}</p>
            </Button>
          );
        })}
      </>
    );
  };

  const handleBackButton = () => {
    setActiveSelectedItemIndex(-1); //reset the active selected line drawing item 
    setDisplayLineDrawingItems(false); //set to false and go back to the category list
  }

  const handlePopupClose = () => {
    setAnnotationState({editMode: false});
    return true;
  }

  const handlePopupCancel = () => {
    setAnnotationState({editMode: false});
    props.onCancel();
  }

  return (
    <>
      <Popup
        id={`PopupEditor_${props.id}`}
        className="PopupContainer"
        opened={popupOpened}
        onPopupClosed={closePopup}
        tabletFullscreenx
        push
      >
        <Page>
          <Navbar className="PopupNavBar" noShadow="true">
            {/* <NavLeft>
              {displayLineDrawingItems && <Button className='PopUpBackButton' onClick={()=>setDisplayLineDrawingItems(false)} round>Back</Button> }
            </NavLeft> */}
            
            <NavLeft>
              {displayLineDrawingItems && (
                <Button
                  id="PopupEditor_Back"
                  className="PopUpBackButton"
                  onClick={handleBackButton}
                  round
                >
                  <i class="icon icon icon-back"></i>Back
                </Button>
              )}
            </NavLeft>
            <NavTitle>What can you see here?</NavTitle>
            <NavRight>
              
              <Button
                id="PopupEditor_Cancel"
                className="PopUpCancelButton"
                onClick={()=>handlePopupCancel()}
                round
              >
                Cancel
              </Button>
              {displayLineDrawingItems && annotationState.editMode && (
                //disable button when no line drawint is selected in both Save and Create mode.
                activeSelectedItemIndex != -1 ? <Button id="PopupEditor_Save" className="PopUpSaveButton" round popupClose={()=>handlePopupClose()}>Save</Button> : 
                <Button id="PopupEditor_Save" className="PopUpSaveButton disabledButton" round popupClose>Save</Button>
              )}
              {displayLineDrawingItems && !annotationState.editMode && (
                activeSelectedItemIndex != -1 ? <Button id="PopupEditor_Create" className="PopUpSaveButton" round  popupClose={()=>handlePopupClose()}> Create </Button> : 
                <Button id="PopupEditor_Create" className="PopUpSaveButton disabledButton" round> Create </Button>
              )}
            </NavRight>
          </Navbar>
          <Block>
            <ImageCropPreview 
            id="ImageCropPreview"
            image={imgObject}
            crop={cropArea}
            />
          </Block>
          <Block>
            <Row className="CategoriesButtonWrapper">
              {!displayLineDrawingItems &&
                categories.map((cat, index) => {
                  const categoryClassName = cat.replaceAll(' ', '_').replaceAll('/', '_').toLowerCase();
                  const categoryName = toPascalCase(cat);
                  
                  return (
                    <>
                      <Button
                        id={`CategoryButton_${categoryName}_${index}`}
                        key={`${cat}-${index}`}
                        className={`CategoriesButton ${categoryClassName}`}
                        onClick={() => {
                          setDisplayLineDrawingItems(true);
                          SetCurrentCategory(cat);
                        }}
                      >
                        <div><img id={`CategoryImage_${index}`} src={`./assets/category_${categoryClassName}.png`} /></div>
                        {cat}
                      </Button>
                    </>
                  );
                })}

              {displayLineDrawingItems &&
                displayLineDrawingIcons(currentCategory)}
            </Row>
          </Block>
        </Page>
      </Popup>
    </>
  );
  
}