const devEnvironment = process.env.NODE_ENV !== 'production'

let envBackend;
try {
    envBackend = process.env.REACT_BACKEND_URL;
}catch(e){
    console.log("Environment variable not set")
}

const backend = window?.__RUNTIME_CONFIG__?.api_uri + '/';

const apiURLS = {
    backend
};

export default apiURLS;
