import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import {
    Page,
    PageContent,
    Navbar,
    Badge,
    BlockTitle,
    Block,
    Checkbox,
    Icon,
    List,
    ListItem,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Popup,
    Popover,
    Sheet,
    f7,
    Toolbar,
    Button,
    SwipeoutActions,
    SwipeoutButton,
    Subnavbar,
    Segmented,
    Row,
    Col,
    Progressbar,
  } from 'framework7-react';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { db } from "../../db";
import apiURLS from '../../api';
import { useLiveQuery } from "dexie-react-hooks";
import { PageWrapper } from "../../pages/PageWrapper";
import { useAuth } from "react-oidc-context";
import { getCacheKey } from "../../helper/getCacheKey";

axiosRetry(axios, { retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return error.response.status === 418 || error.response.status === 500 || error.response.status === 503 || error.code === "ERR_NETWORK";
    },
  }
);

const OnlineCollections = (props) => {

  const auth = useAuth();

    const intialManagePageObject = {
        isLoading: true,
        isDownloading: true,
        showCheckboxes: false,
        collections: [],
        selectedCollections: [],
        downloadedCollections: [],
      };

    
  const [collections, setCollections] = useState([]);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(false);


  // Array containing collection id's of saved Collections
  const downloadedCollections = useLiveQuery(async () => {
    try{
      let collection = await db.collections.toCollection()
      return await collection.primaryKeys();
    } catch(err) {
      console.log('downloadedCollections error', err)
    }
  });

    // Retrieses the collections from api and stores in Collections state
    useEffect(() => {
        const fetchCollection = async () => {
            f7.dialog.preloader('Loading collections...');
            const collectionURL = `${apiURLS.backend}collections/?page_size=20`;
            const response = await axios
            .get(collectionURL)
            .then((response) => {
                f7.dialog.close();
                return response;
            })
            .catch((error) => {
                f7.dialog.close();
                f7.dialog.alert(`Failed to load collections due to: ${error.message} - ${error.response.statusText}`, 'Download Failed');
            })

            const collectionsResult = response.data.results;
            setCollections(collectionsResult);
            // Update current page number
            setPage(1);
            // Check if next page exists
            if(response?.data?.next){
              setNextPage(true);
            }else{
              //Disable infinite scroll if no next page
              setShowPreloader(false);
            }
        };
        fetchCollection();
    }, []);

    // Notification for when download was completely successful
    const notificationSucesss = () => f7.notification.create({
        
        titleRightText: 'now',
        subtitle: 'Download Succeeded',
        text: 'The selected Collection was successfully downloaded!',
        closeTimeout: 4000,
    }).open();

    const downloadCollection = async (collectionURL) => {

      // Create progress dialog, locks user input
      const progressDialog = f7.dialog.progress('Downloading Metadata', 0);

      try{
        const collectionData = await downloadCollectionData(progressDialog, collectionURL);

        const imageModels = await downloadImageModels(progressDialog, collectionData);

        const imageObjects = await downloadImageObject(progressDialog, imageModels);

        const thumbnailObjects = await downloadThumbnailObjects(progressDialog, imageModels);

        // Everything done, transaction save all data
        db.transaction('rw', db.collections, db.images, db.image_cache, () =>{
          // Add collection to indexeddb
          db.collections.add(collectionData);

          // Add image model
          db.images.bulkPut(imageModels);

          // Attempt to add images objects to image cache
          db.image_cache.bulkAdd(imageObjects);

          // Attempt to add thumbnail objects to image cache
          db.image_cache.bulkAdd(thumbnailObjects);

        }).then(()=> {
          progressDialog.close();
          notificationSucesss();
        }).catch((err) =>{
          console.log(err);
          progressDialog.close();
          f7.dialog.alert('Collection and Images were unable to be saved to your device, please try again later.', 'Download Failed');
        })
      }catch(e){
        console.error(e);
      }
  }

  const downloadCollectionData = async (progressDialog, collectionURL) => {
     // Attempt to download collection data
     const response = await axios
     .get(collectionURL)
     .then((data) => {
       return data;
     })
     .catch((error) => {
       progressDialog.close();
       return error;
     });

     // Exit if collection not found
     if (!response.data) {
         progressDialog.close();
         f7.dialog.alert(`Collection metadata failed to download due to: ${response.message}`, 'Download Failed');
         throw new Error(response);
     }
     return response.data
  }

  const downloadImageModels = async (progressDialog, collectionData) => {
    let totalModels = collectionData.images.length
    let currentModelDownloads = 0;

    // Get image models
    const imageModelResponses = await Promise.allSettled(
        collectionData.images.map((imageURL) => axios.get(imageURL).then((data) => {
          currentModelDownloads += 1;
          progressDialog.setText(`${totalModels - currentModelDownloads} models remaining`)
          progressDialog.setProgress(currentModelDownloads/totalModels * 100)
          return data
        }))
    )

    // Filter promise results
    const fulfilledModel = imageModelResponses.filter(result => result.status === 'fulfilled').map(result => result.value.data);
    const rejectedModel = imageModelResponses.filter(result => result.status === 'rejected').map(result => result.reason);

    // Exit if any errors
    if (rejectedModel.length){
        const firstError = rejectedModel[0];
        progressDialog.close();
        f7.dialog.alert(`Image metadata failed to download due to: ${firstError.message} `, 'Download Failed');
        throw new Error(firstError);
    }
    return fulfilledModel;
  }

  const downloadImageObject = async (progressDialog, imageModels) =>{

    // Track total image downloads
    let totalDownloads = imageModels.length;

    let totalImageDownloads = totalDownloads;
    let currentImageDownloads = 0;

    // Stores downloaded images for adding to indexedDB
    let downloadedImages = [];
    let imageErrors = [];
    let imagesSkipped = 0;

    progressDialog.setProgress(0);
    progressDialog.setTitle('Downloading Images')

    // Sequentially get images from backend, any errors will stop the download
    for (const image of imageModels) {
      try{
          const url = image.image_object;
          const key = getCacheKey(url);

          // Only download images that don't exist on device
          const exists = await db.image_cache.get(key);
          if(!exists){
            const imageData = await axios.get(url, {headers: {Accept: "image/jpg,image/jpeg,image/png,image/svg+xml,image/*;q=0.8,*/*;q=0.5", "X-RockArt-Cache": 'True'}, responseType: 'blob'})
            const timestamp = Date.now();
            const headers = [imageData.headers];
            const data = imageData.data;
            downloadedImages.push({
              key,
              url,
              timestamp,
              headers,
              image: data
            })
            currentImageDownloads += 1;
            progressDialog.setProgress(currentImageDownloads/totalImageDownloads * 100)
            progressDialog.setText(`${totalImageDownloads - currentImageDownloads} images remaining`)
          }else{
            // We intentially skipped a download, therefore decrease total downloads by 1
            totalImageDownloads--;
            imagesSkipped++;
          }

      }catch(err){
        console.error(err);
        imageErrors.push(err);
        break
      }
    }

    console.warn(`${imagesSkipped} images were skipped due to already being cached `);

    // Check for any image download errors
    if (imageErrors.length){
      // Get error from axios request
      const firstError = imageErrors[0];
      let err = firstError.message;
      progressDialog.close();
      f7.dialog.alert(`Collection image data failed to download due to: ${err} `, 'Download Failed');
      throw new Error(firstError);
    }

    // Verify successful downloads equals total image requests
    if (currentImageDownloads !== totalImageDownloads){
      progressDialog.close();
      f7.dialog.alert(`Collection image data validation failed, only ${currentImageDownloads} out of ${totalImageDownloads} were downloaded, reason unknown.`, 'Download Failed');
      throw new Error('Image data validation failed');
    }

    return downloadedImages;
  }

  const downloadThumbnailObjects = async (progressDialog, imageModels) => {

    // Track total image downloads
    let totalDownloads = imageModels.length;

    let totalThumbnailDownloads = totalDownloads;
    let currentThumbnailDownloads = 0;

    // Stores downloaded images for adding to indexedDB
    let downloadedThumbnails = [];
    let thumbnailErrors = [];
    let thumbnailsSkipped = 0;

    progressDialog.setProgress(currentThumbnailDownloads/totalThumbnailDownloads * 100)
    progressDialog.setTitle('Downloading Thumbnails')

    // Sequentially get thumbnails from backend, any errors will stop the download
    for (const image of imageModels) {
      try{
          const url = image.thumbnail;
          // Check for truthy thumbnail url before checking cache and downloading
          const key = getCacheKey(url);

          // Only download images that don't exist on device
          const exists = await db.image_cache.get(key);
          if(!exists){
            const imageData = await axios.get(url, {headers: {Accept: "image/jpg,image/jpeg,image/png,image/svg+xml,image/*;q=0.8,*/*;q=0.5", "X-RockArt-Cache": 'True'}, responseType: 'blob'})
            const timestamp = Date.now();
            const headers = [imageData.headers];
            const data = imageData.data;
            downloadedThumbnails.push({
              key,
              url,
              timestamp,
              headers,
              image: data
            })
            currentThumbnailDownloads += 1;
            progressDialog.setProgress(currentThumbnailDownloads/totalThumbnailDownloads * 100)
            console.log(totalThumbnailDownloads,currentThumbnailDownloads)
            progressDialog.setText(`${totalThumbnailDownloads - currentThumbnailDownloads} thumbnails remaining`)
          }else{
            // We intentially skipped a download, therefore decrease total downloads by 1
            totalThumbnailDownloads--;
            thumbnailsSkipped++;
          }


      }catch(err){
        console.error(err);
        thumbnailErrors.push(err);
        break
      }
    }

    console.warn(`${thumbnailsSkipped} thumbnails were skipped due to already being cached`);

    // Set to 100% if the thumbnails are finished
    progressDialog.setProgress(100);

    // Check for any image download errors
    if (thumbnailErrors.length){
      // Get error from axios request
      const firstError = thumbnailErrors[0];
      let err = firstError.message;
      progressDialog.close();
      f7.dialog.alert(`Collection image data failed to download due to: ${err} `, 'Download Failed');
      throw new Error(firstError);
    }

    // Verify successful downloads equals total thumbnail requests
    if (currentThumbnailDownloads !== totalThumbnailDownloads){
      progressDialog.close();
      f7.dialog.alert(`Collection thumbnail data validation failed, only ${currentThumbnailDownloads} out of ${totalThumbnailDownloads} were downloaded, reason unknown.`, 'Download Failed');
      throw new Error('Thumbnail data validation failed');
    }

    return downloadedThumbnails

  }

  const renderListItemAfter = (collectionId) => {
      return (
        <>
          <Badge color="green">DOWNLOADED</Badge>
        </>
      );
  };

  const allowInfinite = useRef(true);

  const [showPreloader, setShowPreloader] = useState(true);

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;

    // Check if there are any more pages to load
    if(!nextPage){
      setShowPreloader(false);
      return;
    }

    const collectionURL = `${apiURLS.backend}collections/?page=${page+1}&page_size=20`;

    // TODO - Add better catch resolution, probably needs to change some globals
    const response = await axios
    .get(collectionURL)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        f7.dialog.alert(error);
    })

    const collectionsResult = response?.data?.results;

    // Add to grabbed collection data to collections
    if(collectionsResult){
      setCollections((collection) => [...collection, ...collectionsResult])
    }

    // Update next page
    if(response?.data?.next){
      setNextPage(true);
    }else{
      setNextPage(false);
    }

    setPage((page) => page + 1);

    allowInfinite.current = true;

  };

  const navbar = (
    <Navbar>
      <NavTitle>Manage Collections</NavTitle>
      <NavRight>
        <Link
          iconIos="f7:lock"
          iconAurora="f7:lock"
          iconMd="material:Login"
          onClick={() =>
            auth.signoutRedirect({
              post_logout_redirect_uri:
                window.location.protocol + "//" + window.location.host + "/",
            })
          }
        >
          Logout
        </Link>
      </NavRight>
      <Subnavbar id="ManageCollectionSubNavBar">
        <Segmented>
          <Button tabLinkActive animate={false}>
            Online Collections
          </Button>
          <Button href='/manage-collection/local-collection/' animate={false}>
            Downloaded Collections
          </Button>
        </Segmented>
      </Subnavbar>
    </Navbar>
  );

    return ( 
        <PageWrapper
        infinite
        infiniteDistance={50}
        infinitePreloader={showPreloader}
        onInfinite={loadMore}
        navbar={navbar}>
            <BlockTitle style={{fontSize: '14px', color: '#666', paddingLeft:'14px'}}>Online Collections</BlockTitle>
            <List className='ManageCollectionLists'  mediaList>
            {collections?.map((collection, index) => {
            return (
              <ListItem
                key={index}
                id={`${collection.title.replace(/ /g,"_")}${collection.id}`}
                className={`onlineCollectionsList 
                ${
                  downloadedCollections?.includes(collection.id)
                    ? "disableDownloadedCollection"
                    : ""
                } ${!collection?.images.length && "disabe-listItem"}`}
                name={`collection-checkbox-${collection.id}`}
                title={collection.title}
                after={
                  downloadedCollections?.includes(collection.id)
                    ? renderListItemAfter(collection.id)
                    : ""
                }
                text={!collection?.images.length && "No images found in collection"}
                value={collection.id}
                onChange={(e) => handleCheckboxChange(e, collection.url)}
                swipeout
              >
                <SwipeoutActions
                  right={
                    downloadedCollections?.includes(collection.id)
                      ? false
                      : true
                  }
                >
                    <SwipeoutButton
                        id="DownloadCollections"
                        className={
                        downloadedCollections?.includes(collection.id)
                            ? `display-none`
                            : "swipeout-close"
                        }
                        onClick={() => {
                            downloadCollection(collection.url)
                        }}
                    >
                        Download
                    </SwipeoutButton>
                </SwipeoutActions>
              </ListItem>
            );
          })}
        </List>
      </PageWrapper>
    )

}

export default OnlineCollections