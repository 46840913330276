const image_url_path = "./assets/MotifLineDrawing/plants";

export const Plants =  [
    {
        name: "fruits",
        sub_category: "",
        image:`${image_url_path}/plant_fruits.svg`,
    },
    {
        name: "vine",
        sub_category: "",
        image:`${image_url_path}/plant_vine.svg`,
    },
    {
        name: "waterlily",
        sub_category: "",
        image:`${image_url_path}/plant_waterlily.svg`,
    },
    {
        name: "tree or branch of a tree",
        sub_category: "",
        image:`${image_url_path}/plant_tree_or_branch_of_a_tree.svg`,
    },
    {
        name: "yam",
        sub_category: "",
        image:`${image_url_path}/plant_yam.svg`,
    },
]