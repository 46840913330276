const image_url_path = "./assets/MotifLineDrawing/tracks";

export const Tracks =  [
    {
        name: "bird",
        sub_category: "",
        image:`${image_url_path}/tracks_bird.svg`,
    },
    {
        name: "human",
        sub_category: "",
        image:`${image_url_path}/tracks_human.svg`,
    },
    {
        name: "macropod",
        sub_category: "",
        image:`${image_url_path}/tracks_macropod.svg`,
    },
    // {
    //     name: "other tracks",
    //     sub_category: "",
    //     image:`${image_url_path}/tracks_other_tracks.svg`,
    // },
]