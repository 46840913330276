import React from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  NavTitle,
  NavTitleLarge,
  NavRight,
  Link,
  Toolbar,
  Block,
  BlockTitle,
  List,
  ListItem,
  Row,
  Col,
  Button
} from 'framework7-react';

import titleImage from "../assets/Noelene-and-Anthony-Dec-2019.jpg"
import aboriginalFlag from "../assets/aboriginal.svg"
import torresStraitIslanderFlag from "../assets/torres-strait-islander.svg"
import "../css/titlebanner.css"

import { useOnlineStatus } from "../hooks/useOnlineStatus";
import {PageWrapper} from "./PageWrapper";
import "./home.scss"

const HomePage = () => {
  const isOnline = useOnlineStatus();

  const navbar = (
    <Navbar className='HomeNavBar' noShadow>
      <NavTitle title="Digitising the Deep Past" subtitle="Rockart Spotlight"> <img src='./assets/logo.png' style={{width: '50px'}} alt={'Logo - Digitising the Deep Past'} /></NavTitle>
    </Navbar>
  );

  const renderHomePage = () => {
    return (
      <PageWrapper className="HomePage" name="home" navbar={navbar}>
      
        {/* Page content */}

        <div className="titlecontainer">
        
          <img src={titleImage} alt="Rockart Title Image" />
          <div className="text-block">
            Digitising the Deep Past
          </div>
        </div>



        <List>
          <Block  style={{ position:"relative", textAlign: "center" }}>
           
            <h5>Acknowledgment of Country</h5>
            <p>
              We acknowledge the traditional owners of the lands from across Queensland<br />
              and pay respect to Elders – past, present and emerging – for they hold the memories,
              traditions, cultures and hopes of Aboriginal and Torres Strait Islander peoples across the state.
            </p>
            <p>
              <img src={aboriginalFlag} style={{ width: "50px", marginRight: "5px" }} />
              <img src={torresStraitIslanderFlag} style={{ width: "50px", marginLeft: "5px" }} />
            </p>
          </Block>

          <Block  style={{ textAlign: "center" }}>
            <p>To get started, head over to "Collections" at the bottom of this page.</p>
            <Button id="StartCollectionButton" href="/collection/" large fill raised>Start</Button>
          </Block>
        </List>
        <div className='imgDecor-HomePage'></div>
      </PageWrapper>
    )
  }

  return (
    <>
      {/* {isOnline && renderHomePage()}
      {!isOnline && <p>You are offline now. </p>} */}
      {renderHomePage()}
    </>
  )
};
export default HomePage;