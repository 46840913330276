import React, {useState, useEffect} from 'react';
import "./PinCodeInput.scss";

import {db} from '../db'

/**
 * 
 * @param {string} collectionPin - The pin code for the collection
 * @param {string} url - The url to navigate to when the pin code is valid
 * @param {object} f7router - The f7router object to navigate to the url
 * 
 * @returns {object} - The PinCodeInput component
 * 
 * @description - The PinCodeInput component is used to validate the pin code for the collection
 *  
 */

const PinCodeInput = ({collectionPin, url, f7router}) => {

  const MAX_PIN_LENGTH = 4; // The maximum length of the pin code to allow 4 digits PIN only

  const [value, setValue] = useState('');
  const [validPinCode, setValidPinCode] = useState('');
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setValidPinCode(collectionPin)
  }, [collectionPin])

  const validatePinInputField = (pinCode) => {
    // Check if pin code is valid
    const regex = /^\d{4}$/;
    const validation = regex.test(pinCode);
    return validation;
  }

  const checkPinCode = (pinCode) => {
    console.log('checkPinCode', pinCode)
    const validateInput = validatePinInputField(pinCode);
    const isPinCodeValid = pinCode === validPinCode;
    
    setIsPinCodeValid(isPinCodeValid);
    
    if (!isPinCodeValid || !validateInput) {
        setValue("")
        setErrorMsg("Invalid PIN")
        setTimeout(() => {
            setErrorMsg("")
        }, 1500)
    };

    if (isPinCodeValid && validateInput) {f7router.navigate(url)};
  }


  const handleChange = event => {
    //Allow only numbers
    const pinCode = event.target.value.replace(/\D/g, '');

    setValue(pinCode);
    console.log('handleChange',pinCode, event.currentTarget.value, event.currentTarget.value.length, event.target.value.length, value.length);
    
    //Check if pin code is valid
    if(event.target.value.length == 4) {
        //console.log("Valid", value, pinCode);
        checkPinCode(pinCode);
    }
  };

  return (
    <div className='PinCodeWrapper'>
      <div className='PinCodeInput'>
        <label>{!isPinCodeValid && errorMsg}</label>
        <input
          type="text"
          placeholder={`Enter 4-digit PIN`}
          value={value}
          onChange={handleChange}
          inputMode="numeric"
          maxLength={`${MAX_PIN_LENGTH}`}
        />
      </div>
    </div>
  );
}

export default PinCodeInput;