import React from "react";
import {
  Navbar,
  BlockTitle,
  List,
  ListItem,
  AccordionContent,
  Block,
  AccordionItem,
  AccordionToggle,
  Row,
  Col,
} from "framework7-react";
import { PageWrapper } from "./PageWrapper";
import "./TutorialPage.scss";
import CREATE_ANNOTATION from "../assets/tutorials/create-annotation.mp4";
import EDIT_ANNOTATION_ON_IMAGE from "../assets/tutorials/edit-annotation-on-image.mp4";
import DELETE_ANNOTATION_ON_IMAGE from "../assets/tutorials/delete-annotation-on-image.mp4"
import DELETE_ANNOTATION_ON_SIDE_PANEL from "../assets/tutorials/delete-annotation-on-side-panel.mp4";
import SIDE_PANEL_NAVIGATION from "../assets/tutorials/side-panel-navigation-show-hide.mp4"

const navbar = (
  <Navbar title="Tutorial" id="TutorialNavBar" />
);

const TutorialPage = () => (
  <PageWrapper navbar={navbar}>

    <BlockTitle>Basic usage</BlockTitle>
    <List id="TutorialList" accordionList>
      <ListItem className="accordionItem" accordionItem title="Create an annotation">
        <AccordionContent className="AccordionContent">
          <Block>
            <Row>
              <Col width={35}>
                <ol>
                  <li>
                   Touch and Drag on the image.
                  </li>
                  <li>
                    Select a category from the popup menu. <br /> (e.g. Aborgiainal object)
                  </li>
                  <li>
                   Select an object.  <br />(e.g. Boomerang)
                  </li>
                  <li>
                   Touch on CREATE button. <br />(e.g. Boomerang)
                  </li>
                </ol>
              </Col>
              <Col width={65}>
                <video className="createAnnotationVideo" controls muted>
                  <source src={CREATE_ANNOTATION} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Block>
        </AccordionContent>
      </ListItem>

      <ListItem className="accordionItem" accordionItem title="Edit / Update an annotation on the image">
        <AccordionContent className="AccordionContent">
          <Block>
            <Row>
              <Col width={35}>
                <ol>
                  <li>Touch on the existing annotation on the image to display the option menu</li>
                  <li>Touch on EDIT button in the option menu.</li>
                  <li>Select a category from the popup menu.</li>
                  <li>Select the object.</li>
                  <li>Touch on SAVE button.</li>
                </ol>
              </Col>
              <Col width={65}>
                <video className="editAnnotationOnImageVideo" controls muted>
                  <source src={EDIT_ANNOTATION_ON_IMAGE} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Block>
        </AccordionContent>
      </ListItem>

      <ListItem className="accordionItem" accordionItem title="Delete an annotation on image">
        <AccordionContent className="AccordionContent">
          <Block>
            <Row>
              <Col width={35}>
                <ol>
                  <li>Touch on the existing annotation on the image to display the option menu.</li>
                  <li>Touch on EDIT button in the option menu.</li>
                  <li>Touch on DELETE button in the option menu.</li>
                  <li>Touch on DELETE in the popup confirmation box to delete the selected annotation.</li>
                </ol>
              </Col>
              <Col width={65}>
                <video className="deleteAnnotationOnImageVideo" controls muted>
                  <source src={DELETE_ANNOTATION_ON_IMAGE} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Block>
        </AccordionContent>
      </ListItem>

      <ListItem className="accordionItem" accordionItem title="Delete an annotation on side panel">
        <AccordionContent className="AccordionContent">
          <Block>
            <Row>
              <Col width={35}>
                <ol>
                <li>Touch on Edit button on the top right navigation bar.</li>
                <li>Swipe left on the listed annotation item to access the Delete button.</li>
                <li>Touch on Delete button.</li>
                <li>Touch on Delete in the popup confirmation box to delete the selected annotation.</li>
                </ol>
              </Col>
              <Col width={65}>
                <video className="deleteAnnotationOnSidePanelVideo" controls muted>
                  <source src={DELETE_ANNOTATION_ON_SIDE_PANEL} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Block>
        </AccordionContent>
      </ListItem>

      <ListItem className="accordionItem" accordionItem title="Side panel navigation and show/hide annotation">
        <AccordionContent className="AccordionContent">
          <Block>
            <Row>
              <Col width={35}>
                <p>Side panel contains the Annotation menu that provides you an overview of all created annotations together with the preview of rockart image.</p>
                <ol>
                <li>Touch on Edit button on the top right navigation bar.</li>
                <li>Swipe left on the listed annotation item to access the buttons.</li>
                <li>Touch on Show button to display the thumbnail of the object image in the Preview rockart image on the top.</li>
                <li>Touch on Hide button to hide the thumbnail.</li>
                </ol>
              </Col>
              <Col width={65}>
                <video className="sidePanelNavigationVideo" controls muted>
                  <source src={SIDE_PANEL_NAVIGATION} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Block>
        </AccordionContent>
      </ListItem>

    </List>
  </PageWrapper>
);

export default TutorialPage;
