// db.js
import Dexie from 'dexie';

export const db = new Dexie('myDatabase');
db.version(4).stores({
  collections: 'id, url, title, notes, images, images_id, status',
  images: 'id, url, title, location, image_object, thumbnail, created, updated, annotations, *collections, *collection_ids',
  annotations: 'id, session_id, collection_id, image_id, data, geometry, selection',
  image_objects: 'id, image_object',
  image_cache: "key, url"
});
