import React, { useState, useEffect, useContext } from 'react';
import {
  Page,
  PageContent,
  Navbar,
  Badge,
  BlockTitle,
  Block,
  Checkbox,
  Icon,
  List,
  ListItem,
  NavLeft,
  NavRight,
  NavTitle,
  Link,
  Popover,
  Sheet,
  f7,
  Toolbar,
} from 'framework7-react';
import "./ManageCollectionsPage.scss"
// import { ManageCollectionsStoreProvider } from '../stateManager/stores/ManageCollectionsStore';
import { useOnlineStatus } from "../hooks/useOnlineStatus";
import { ManageCollections } from "../components/ManageCollections/index"
import { useAuth } from "react-oidc-context";
import LoginPage from '../pages/LoginPage';
import { PageWrapper } from "./PageWrapper";
import LocalAnnotations from '../components/ManageCollections/LocalAnnotations';

const LocalCollectionsPage = (props) => {
  const auth = useAuth();
  const isOnline = useOnlineStatus();
  const { f7route, f7router } = props;

  // Add router listener to trigger a logout once on leaving a page
  useEffect(() => {
    f7.views.main.router.once("routeChanged", async (newRoute, previousRoute, router) => {
      if(newRoute.path !== "/manage-collection/online-collection/"){
      auth.revokeTokens(["access_token", "refresh_token"]).then(() => {
        console.log('tokens revoked')
        return auth.removeUser();
      }
      ).then(() => console.log('user removed'))
      .catch((err)=>console.error("revoke token or user removal failed: ", err));
    }
    })
  }, []);

  // Redirect to login page if user is not authenticated
  if (auth.isAuthenticated){
    return (
      // <ManageCollectionsStoreProvider>
          <LocalAnnotations f7route={f7route} f7router={f7router} />
      // </ManageCollectionsStoreProvider>
    );
  }else{
    return (
      <Page>
        <LoginPage />
      </Page>
    )
  }
};
export default LocalCollectionsPage;