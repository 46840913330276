import React, { useState, useEffect, useContext } from 'react';
import {
  Page,
  f7,
} from 'framework7-react';
import "./ManageCollectionsPage.scss"
import { useOnlineStatus } from "../hooks/useOnlineStatus";
import { ManageCollections } from "../components/ManageCollections/index"
import { useAuth } from "react-oidc-context";
import LoginPage from '../pages/LoginPage';
import { PageWrapper } from "./PageWrapper";
import OnlineCollections from '../components/ManageCollections/OnlineCollections';

const OnlineCollectionsPage = (props) => {
  const auth = useAuth();
  const isOnline = useOnlineStatus();
  const { f7route, f7router } = props;

  // Add router listener to trigger once on leaving a page
  useEffect(() => {
    f7.views.main.router.once("routeChanged", async (newRoute, previousRoute, router) => {
      if(newRoute.path !== "/manage-collection/local-collection/"){
      auth.revokeTokens(["access_token", "refresh_token"]).then(() => {
        console.log('tokens revoked')
        return auth.removeUser();
      }
      ).then(() => console.log('user removed'))
      .catch((err)=>console.error("revoke token or user removal failed: ", err));
    }
    })
  }, []);
  
  //link={`/annotation/${f7route.params.collectionId}/image/${image.id}/`}
  // Redirect to login page if user is not authenticated
  if (auth.isAuthenticated){
    return (
          <OnlineCollections f7route={f7route} f7router={f7router} />
    );
  }else{
    return (
      <Page>
        <LoginPage />
      </Page>
    )
  }
};
export default OnlineCollectionsPage;