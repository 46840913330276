import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import {
    Page,
    PageContent,
    Navbar,
    Badge,
    BlockTitle,
    Block,
    Checkbox,
    Icon,
    List,
    ListItem,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Popup,
    Popover,
    Sheet,
    f7,
    Toolbar,
    Button,
    SwipeoutActions,
    SwipeoutButton,
    Subnavbar,
    Segmented,
    Row,
    Col,
    Progressbar,
  } from 'framework7-react';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosError } from 'axios';
import { db } from "../../db";
import apiURLS from '../../api';
import { useLiveQuery } from "dexie-react-hooks";
import { PageWrapper } from '../../pages/PageWrapper';
import { getCacheKey } from '../../helper/getCacheKey';

const LocalAnnotations = (props) => {

    // Generate uuid for lifetime of component, this will result in all collections uploaded at the same time
    // having the same uuid
    const [sessionUuid, setSessionUuid] = useState('');

    useEffect(() => {
        // Set a uuid on initial render
        setSessionUuid(uuidv4());
    }, []);

    // Array containing local collection 
    const localCollections = useLiveQuery(async () => {
    try{
        return await db.transaction("r", db.collections, db.annotations, async() => {
            const collections = await db.collections.toArray()
            const collectionArr = [];
            // Synchronous addition of of annotation count to each collection
            for (let collection of collections){
                const annotationCount = await db.annotations.where("collection_id").equals(collection.id).count()
                collectionArr.push({...collection, annotationCount: annotationCount});
            }
            return collectionArr;
        }); 
    } catch(err) {
    console.log('downloadedCollections error', err)
    }
    });

    // Notification for when upload was completely successful
    const notificationSucesss = () => f7.notification.create({
 
        titleRightText: 'now',
        subtitle: 'Upload Succeeded',
        text: 'The selected annotations were successfully uploaded!',
        closeTimeout: 5000,
      }).open();

    // Notification for when upload was completely successful
    const notificationAnnotationsDeleted = () => f7.notification.create({
      
        titleRightText: 'now',
        subtitle: 'Annotation Delete',
        text: 'The selected annotations were successfully deleted!',
        closeTimeout: 3500,
    }).open();

    // Notification for when upload was completely successful
    const notificationCollectionDeleted =  () => f7.notification.create({
      
        titleRightText: 'now',
        subtitle: 'Collection Delete',
        text: 'The selected collection was successfully deleted!',
        closeTimeout: 3500,
    }).open();

    const uploadAnnotations = async (collectionId) => {

        // Create progress dialog
        const progressDialog = f7.dialog.progress('Loading assets', 0);

        const annotationsArr = await db.annotations.where("collection_id").equals(collectionId).toArray();
        const totalUploads = annotationsArr.length;
        let currentUploads = 0;
        const url = `${apiURLS.backend}annotations/`;

        const responses = await Promise.allSettled(
            annotationsArr.map(annotation => {
                const data = {
                    image_id: annotation.image_id,
                    collection_id: annotation.collection_id,
                    // Use component session id so similarly timed uploads have the same uuid
                    session_id: sessionUuid,
                    annotation_uuid: annotation.id,
                    text: annotation.data.text,
                    images: annotation.data.image,
                    // Fixing decimals to 15 decimal length
                    height: +annotation.geometry.height.toFixed(15),
                    width: +annotation.geometry.width.toFixed(15),
                    type: annotation.geometry.type,
                    x: +annotation.geometry.x.toFixed(15),
                    y: +annotation.geometry.y.toFixed(15),
                    anchorX: +annotation.selection.anchorX.toFixed(15),
                    anchorY: +annotation.selection.anchorY.toFixed(15),
                    mode: annotation.selection.mode,
                    showEditor: annotation.selection.showEditor
                }

                return axios.post(url, data).then(() => {
                    currentUploads += 1;

                    // Increment progress dialog 
                    progressDialog.setProgress(currentUploads/totalUploads * 100)

                    return Promise.resolve({err: undefined, data: data});
                }).catch((err) => {
                    return Promise.reject({err: err, data: data})
                });

                }
                )
        ).catch(err => console.log('err', err))

        //Close the dialog as upload has either succeeded or failed
        progressDialog.close()
            
        // Determine failed uploads
        const failures = responses.filter(response => response?.status === "rejected");

        console.log('failures', failures);


        // Determine successful uploads
        const success = responses.filter(response => response?.status === "fulfilled");

        console.log('success',success);

        const succesKeys = [];
        
        // Get annotation keys for each succesfully uploaded annotation
        success.forEach(annotation => {
            console.log(annotation);
            succesKeys.push(annotation.value.data.annotation_uuid);
        })

        // Bulk delete success annotation keys
        const result = await db.annotations.bulkDelete(succesKeys);

        // Display success or failure alerts
        if (failures.length){
            f7.dialog.alert('Some annotations could not be uploaded, please try again later.', 'Upload Failed');
        }else{
            notificationSucesss();
        }
    }

    // Delete annotations, with no dialog or ui effects
    const deleteAnnotations = async (collectionId) => {
        try {
            const annotation_result = await db.annotations.where("collection_id").equals(collectionId);
            const keys = await await annotation_result.primaryKeys();
            await db.annotations.bulkDelete(keys);
            return Promise.resolve();
        }catch(err){
            return Promise.reject("Deletion Failed, try again later");
        }
    }

    // Delete annotations and gives popups
    const deleteCollectionAnnotations = (collectionId) => {
        deleteAnnotations(collectionId)
        .then(() => notificationAnnotationsDeleted())
        .catch((err) => f7.dialog.alert('Some annotations could not be deleted.', 'Deletion Failed'))
    }

    // Delete Collection

    const deleteCollection = async (collection) => {
        try{
            await deleteAnnotations(collection.id);
            await db.collections.delete(collection.id)
            return Promise.resolve();
        }catch(err){
            console.error(err);
            return Promsie.reject();
        }
    }

    // Set operator difference
    const difference = (setA, setB) => {
        const _difference = new Set(setA);
        for (const elem of setB) {
          _difference.delete(elem);
        }
        return _difference;
      }

    // Delete all images for target collection, ignores images in use by other collections
    const deleteCollectionImage = async (collection) => {
        const usedImageSet = new Set();
        const targetImageSet = new Set(collection.images_id);
        const allCollection = await db.collections.toArray();

        // Get all used image ID's
        allCollection.forEach(thisCollection => {
            // Don't add target collection images
            if (collection.id !== thisCollection.id){
                thisCollection.images_id.forEach(id => usedImageSet.add(id));
            }
        })

        // Set Difference between all collections and target collection
        const imageSetDifference = difference(targetImageSet,usedImageSet);
        const imageDifferenceArr = [...imageSetDifference];

        const exclusiveImages = await db.images.bulkGet(imageDifferenceArr);

        // Get image_object and cache keys, using for loop instead of map for speed
        let image_object_host = [];
        let thumbnail_host = [];
        // Interating through each exclusive image
        for (let index = 0; index < exclusiveImages.length; index++) {
            // Get full url for image and thumbnail
            const image = exclusiveImages[index].image_object;
            const thumbnail = exclusiveImages[index].thumbnail;

            // Remove search parameters
            const image_url = getCacheKey(image)
            const thumbnail_url = getCacheKey(thumbnail);

            image_object_host.push(image_url);
            thumbnail_host.push(thumbnail_url);
        }

        // Transaction delete cache and objects
        return db.transaction('rw', db.image_cache, db.image_objects, db.images, async () =>{
            await db.image_cache.bulkDelete(image_object_host);
            await db.image_cache.bulkDelete(thumbnail_host);
            await db.images.bulkDelete(imageDifferenceArr);
        }).then(()=> {
            console.log("Deleted cache");
        }).catch((error) => {
            console.error('collection error:', error)
            return Promise.reject(error);
        });

    }

    // Deletes collections and respective images, otherwise displays error alert
    const deleteCollectionhandler = (collection) => {
        deleteCollectionImage(collection).then(() => {
            deleteCollection(collection).then(() =>
            notificationCollectionDeleted())
        }).catch((err) => f7.dialog.alert(`The collection could not be deleted due to ${err}`, 'Deletion Failed'));
    }

    const navbar = (
        <Navbar>
          <NavTitle>Manage Collections</NavTitle>
          <NavRight>
            <Link
              iconIos="f7:lock"
              iconAurora="f7:lock"
              iconMd="material:Login"
              onClick={() =>
                auth.signoutRedirect({
                  post_logout_redirect_uri:
                    window.location.protocol + "//" + window.location.host + "/",
                })
              }
            >
              Logout
            </Link>
          </NavRight>
          <Subnavbar id="ManageCollectionSubNavBar">
            <Segmented>
              <Button href='/manage-collection/online-collection/' animate={false}>
                Online Collections
              </Button>
              <Button tabLinkActive animate={false}>
                Downloaded Collections
              </Button>
            </Segmented>
          </Subnavbar>
        </Navbar>
      );

    return ( 
        <PageWrapper
        navbar={navbar}>
            <BlockTitle style={{fontSize: '14px', color: '#666', paddingLeft:'14px'}}>Local Collections</BlockTitle>
            <List className='ManageCollectionLists' mediaList>
            {localCollections?.map((collection, index) => {
                return(
                    <ListItem
                    key={index}
                    id={collection.id}
                    className={`localCollectionsListItem`}
                    name={`collection-checkbox-${collection.id}`}
                    title={collection.title}
                    badge={`${collection.annotationCount}`}
                    after={collection?.pin && `PIN: ${collection?.pin}`}
                    checkbox={false}
                    value={collection.id}
                    noChevron={false}
                    onChange={(e) => handleCheckboxChange(e, collection.url)}
                    checked={true}
                    swipeout
                    >
                        <SwipeoutActions
                        right={true}
                        >
                            <SwipeoutButton
                                id="PublishAnnotations"
                                className={
                                localCollections?.annotationCount
                                    ? `display-none`
                                    : "swipeout-close"
                                }
                                onClick={() => {
                                    uploadAnnotations(collection.id)
                                }}
                            >
                                Publish Annotations
                            </SwipeoutButton>
                            <SwipeoutButton
                                id="DeleteAnnotations"
                                className={
                                localCollections?.annotationCount
                                    ? `display-none`
                                    : "swipeout-close"
                                }
                                onClick={() => {
                                    deleteCollectionAnnotations(collection.id)
                                }}
                            >
                                Delete Annotations
                            </SwipeoutButton>
                            <SwipeoutButton
                                id="DeleteCollection"
                                on
                                className={
                                localCollections?.annotationCount
                                    ? `display-none`
                                    : "swipeout-close"
                                }
                                onClick={() => {
                                    deleteCollectionhandler(collection);
                                }}
                            >
                                Delete Collection
                            </SwipeoutButton>
                        </SwipeoutActions>
                    </ListItem>
                )
            })}
            {!localCollections?.length ? <ListItem title={'No downloaded collections'}></ListItem> : null}
            </List>
        </PageWrapper>
    )

}

export default LocalAnnotations