const image_url_path = "./assets/MotifLineDrawing/human";

export const Human =  [
    {
        name: "left hand",
        sub_category: "hand",
        image:`${image_url_path}/human_hand_left_hand.svg`,
    },
    {
        name: "right hand",
        sub_category: "hand",
        image:`${image_url_path}/human_hand_right_hand.svg`,
    },
    {
        name: "unknown human",
        sub_category: "",
        image:`${image_url_path}/human_unknown_human.svg`,
    },
    {
        name: "male",
        sub_category: "",
        image:`${image_url_path}/human_male.svg`,
    },
    {
      name: "female",
      sub_category: "",
      image:`${image_url_path}/human_female.svg`,
    },
    {
        name: "head",
        sub_category: "",
        image:`${image_url_path}/human_head.svg`,
    },
    {
        name: "left foot",
        sub_category: "foot",
        image:`${image_url_path}/human_foot_left_foot.svg`,
    },
    {
        name: "right foot",
        sub_category: "foot",
        image:`${image_url_path}/human_foot_right_foot.svg`,
    },
    
    // {
    //     name: "left arm",
    //     sub_category: "arm",
    //     image:`${image_url_path}/human_arm_left_arm.svg`,
    // },
    // {
    //     name: "right arm",
    //     sub_category: "arm",
    //     image:`${image_url_path}/human_arm_right_arm.svg`,
    // },
]