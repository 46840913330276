const image_url_path = "./assets/MotifLineDrawing/spirit_creation_figures";

export const SpiritCreationFigures =  [
    {
        name: "Quinkan",
        sub_category: "",
        image:`${image_url_path}/spirit_creation_figures_Quinkan.svg`,
    },
    {
        name: "Timara",
        sub_category: "",
        image:`${image_url_path}/spirit_creation_figures_Timara.svg`,
    },
]