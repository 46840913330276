const image_url_path = "./assets/MotifLineDrawing/water_based_animal";

export const WaterBasedAnimal =  [
    
    {
        name: "fish",
        sub_category: "fish",
        image:`${image_url_path}/water_based_animal_fish_bream.svg`,
    },
    {
        name: "eel",
        sub_category: "",
        image:`${image_url_path}/water_based_animal_eel.svg`,
    },
    {
        name: "long necked turtle",
        sub_category: "",
        image:`${image_url_path}/water_based_animal_long_necked_turtle.svg`,
    },
    {
        name: "short necked turtle",
        sub_category: "",
        image:`${image_url_path}/water_based_animal_short_necked_turtle.svg`,
    },
    {
        name: "sawfish",
        sub_category: "fish",
        image:`${image_url_path}/water_based_animal_fish_sawfish.svg`,
    },
    {
        name: "crocodile",
        sub_category: "",
        image:`${image_url_path}/water_based_animal_crocodile.svg`,
    },
    {
        name: "frog",
        sub_category: "",
        image:`${image_url_path}/water_based_animal_frog.svg`,
    },
    {
        name: "stingray",
        sub_category: "fish",
        image:`${image_url_path}/water_based_animal_fish_stingray.svg`,
    },
    {
        name: "prawn",
        sub_category: "fish",
        image:`${image_url_path}/water_based_animal_fish_prawn.svg`,
    },
    // {
    //     name: "archer fish",
    //     sub_category: "fish",
    //     image:`${image_url_path}/water_based_animal_fish_archer_fish.svg`,
    // },
    // {
    //     name: "barramundi",
    //     sub_category: "fish",
    //     image:`${image_url_path}/water_based_animal_fish_barramundi.svg`,
    // },
    
    // {
    //     name: "fork tail catfish",
    //     sub_category: "fish",
    //     image:`${image_url_path}/water_based_animal_fish_fork_tail_catfish.svg`,
    // },
    // {
    //     name: "long tom",
    //     sub_category: "fish",
    //     image:`${image_url_path}/water_based_animal_fish_long_tom.svg`,
    // },
    
    
    
    // {
    //     name: "eel tail catfish",
    //     sub_category: "fish",
    //     image:`${image_url_path}/water_based_animal_fish_eel_tail_catfish.svg`,
    // },
]