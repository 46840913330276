const image_url_path = "./assets/MotifLineDrawing/geometric_design";

export const GeometricDesign =  [
    {
      name: "circle",
      sub_category: "",
      image:`${image_url_path}/geometric_design_circle.svg`,
    },
    {
        name: "crescent",
        sub_category: "",
        image:`${image_url_path}/geometric_design_crescent.svg`,
    },
    {
        name: "dashes",
        sub_category: "",
        image:`${image_url_path}/geometric_design_dashes.svg`,
    },
    {
        name: "infilled circle",
        sub_category: "",
        image:`${image_url_path}/geometric_design_infilled_circle.svg`,
    },
    {
        name: "maze",
        sub_category: "",
        image:`${image_url_path}/geometric_design_maze.svg`,
    },
    {
        name: "sets of sinuous lines",
        sub_category: "",
        image:`${image_url_path}/geometric_design_sets_of_sinuous_lines.svg`,
    },
    {
        name: "sets of straight lines",
        sub_category: "",
        image:`${image_url_path}/geometric_design_sets_of_straight_lines.svg`,
    },
    {
        name: "single straight line",
        sub_category: "",
        image:`${image_url_path}/geometric_design_signle_straight_line.svg`,
    },
    {
        name: "single sinuous line",
        sub_category: "",
        image:`${image_url_path}/geometric_design_single_sinuous_line.svg`,
    },
    {
        name: "star shape",
        sub_category: "",
        image:`${image_url_path}/geometric_design_star_shape.svg`,
    },
    {
        name: "trident",
        sub_category: "",
        image:`${image_url_path}/geometric_design_trident.svg`,
    },
    {
        name: "dots",
        sub_category: "",
        image:`${image_url_path}/geometric_design_dots.svg`,
    },
]