import React from 'react';
import {
  Page,
  Link,
  Toolbar,
  Navbar,
} from 'framework7-react';

export const PageWrapper = (props) => {

    return (
        <Page {... props} >
                {props?.navbar ? props.navbar : null}
                {props.children}
                <Toolbar className="nav-bottom-bg-color" labels bottom>
                    <Link
                    id="Home"
                    href="/"
                    iconIos="f7:house_fill"
                    iconAurora="f7:house_fill"
                    iconMd="material:home"
                    text="Home"
                    animate={false}
                    />
                    <Link
                    id="Collections"
                    href="/collection/"
                    iconIos="f7:square_list_fill"
                    iconAurora="f7:square_list_fill"
                    iconMd="material:view_list"
                    text="Collections"
                    animate={false}
                    />
                    <Link
                    id="ManageData"
                    href="/manage-collection/online-collection/"
                    iconIos="f7:square_list_fill"
                    iconAurora="f7:square_list_fill"
                    iconMd="material:view_list"
                    text="Manage Data"
                    animate={false}
                    />
                    <Link
                    id="Tutorial"
                    href="/tutorial/"
                    iconIos="f7:question_square"
                    iconAurora="f7:question_square"
                    iconMd="material:QuestionMark"
                    text="Tutorial"
                    animate={false}
                    />
                    <Link
                    id="About"
                    href="/about/"
                    iconIos="f7:info_circle"
                    iconAurora="f7:info_circle"
                    iconMd="material:info"
                    text="About"
                    animate={false}
                    />
                </Toolbar>
        </Page>
    )
}