import React, { useState, useRef, useEffect } from 'react'

  import { canvasPreview } from './canvasPreview'
  import './index.scss'

  export default function ImageCropPreview(props) {
    const previewCanvasRef = useRef(null)

    useEffect(() => {
      canvasPreview(props.image, previewCanvasRef.current, props.crop);
    }, []);

    return(
        <div className='ImageCropPreviewWrapper'>
        {Boolean(props.crop) && (
          <canvas
            ref={previewCanvasRef}
            className='ImageCropPreviewCanvas'
            style={{
              objectFit: 'contain',
              width: props.crop.width,
              maxWidth: '-webkit-fill-available',
              maxHeight: '-webkit-fill-available',
            }}
          />
        )}
      </div>
    )

  }