const image_url_path = "./assets/MotifLineDrawing/land_based_animal";

export const LandBasedAnimal =  [
    // {
    //   name: "buffalo",
    //   sub_category: "",
    //   image:`${image_url_path}/land_based_animal_buffalo.svg`,
    // },
    {
        name: "echidna",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_echidna.svg`,
    },
    {
        name: "macropod",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_macropod.svg`,
    },
    {
        name: "dingo or dog",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_dingo_or_dog.svg`,
    },
    {
        name: "lizard or goanna",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_lizard_or_goanna.svg`,
    },
    {
        name: "snake",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_snake.svg`,
    },
    {
        name: "possum",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_possum.svg`,
    },
    {
        name: "pig",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_pig.svg`,
    },
    {
        name: "horse",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_horse.svg`,
    },
    {
        name: "cow",
        sub_category: "",
        image:`${image_url_path}/land_based_animal_cow.svg`,
    },
    // {
    //     name: "Tasmanian devil",
    //     sub_category: "",
    //     image:`${image_url_path}/land_based_animal_tas_devil.svg`,
    // },
    // {
    //     name: "Tasmanian tiger or thylacine",
    //     sub_category: "",
    //     image:`${image_url_path}/land_based_animal_tas_tiger_or_thylacine.svg`,
    // },
]