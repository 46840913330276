export const DeleteCollectionDialog = {
        buttonText: 'Delete',
        title: 'Delete collection from your device', 
        text:'Press "Delete" to confirm.',
        responseMessage: 'Collection is deleted'
    }

export const DeleteAllCollectionDialog = {
        buttonText: 'Delete all',
        title: 'Delete all collections from your device', 
        text:'Press "Delete all" to confirm.',
        responseMessage: 'All collections are deleted'
    }

export const DeleteAnnotationDialog = {
    buttonText: 'Delete Annotations',
    title: "Delete Collection's Annotations", 
    text:'Press "Delete Annotations" to confirm.',
    responseMessage: "The collection's annotations were deleted",
    errorMessage: "Some of the annotations were unable to be deleted"
}
    

export const ClearAllAnnotationsDialog = {
        buttonText: 'Clear all',
        title: 'Remove all annotations', 
        text:'Press "Clear all" to confirm.',
        responseMessage: ''
}

export const DeleteAnnotationFromMenuDialog = {
        buttonText: 'Delete',
        title: 'Delete the annotation', 
        text:'Press "Delete" to confirm.',
        responseMessage: ''
}