import React, { createContext, useContext, useState } from "react";

export const AnnotationContext = createContext({});

export const AnnotationProvider = ({ children }) => {
    //Provide a global context environment for AnnotationEditor components,
    const initialState ={
      editMode:false,
    }

    const [annotationState, setAnnotationState] = useState(initialState);

    return (
      <AnnotationContext.Provider value={{annotationState, setAnnotationState}}>
        { children }
      </AnnotationContext.Provider>
    )
  }
  
  export function useAnnotation() {
    return useContext(AnnotationContext);
  }