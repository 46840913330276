import React, { useState } from 'react';
import {
  f7,
  Page,
  LoginScreenTitle,
  List,
  ListButton,
  BlockFooter,
  Toolbar,
  Link,
  Block
} from 'framework7-react';
import { useAuth } from "react-oidc-context";
import './LoginPage.scss';
import { PageWrapper } from './PageWrapper';
//import { useOnlineStatus } from "../hooks/useOnlineStatus";
import useNetwork from "../hooks/useNetwork"

export default () => {
  const auth = useAuth();
  //const isOnline = useOnlineStatus();

  const networkState = useNetwork();
  
  const {
    online,
    since,
    downLink,
    downLinkMax,
    effectiveType,
    rtt,
    saveData,
    type,
  } = networkState;

  return (
    <PageWrapper className='LoginPage' noNavbar noSwipeback loginScreen>
      {/* Tabbar for switching views-tabs */}
      <Block>
      <LoginScreenTitle>Manage Collections</LoginScreenTitle>
      <p id="InternetStatusText" style={{textAlign: 'center'}}>Your internet status: {online ? <span style={{color: 'green', fontWeight:'bold'}}>Online</span> : <span style={{color: 'red', fontWeight:'bold'}}>Offline</span>}</p>
      <List form>
      </List>
      <List>
        { online && <ListButton id="LoginButton" className='LoginButton' onClick={() => auth.signinRedirect({redirect_uri: location.origin + '/#!/manage-collection/online-collection/'})}>Login</ListButton>}
        { !online && <ListButton id="LoginButton" className='LoginButton disabled'>Login</ListButton> }
        <BlockFooter>
          To download and change collections, you must login.
          <br />
          Internet Connection Required
        </BlockFooter>
      </List>
      </Block>
    </PageWrapper>
  );
};