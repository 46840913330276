import React from 'react';
import { Page, Navbar, Block, BlockTitle } from 'framework7-react';
import { PageWrapper } from './PageWrapper';

const navbar = (<Navbar title="About" id="AboutNavBar"/>);

const AboutPage = () => (
  <PageWrapper navbar={navbar}>

    <BlockTitle>Rockart Spotlight project</BlockTitle>
    <Block >
      <p>Fugiat perspiciatis excepturi, soluta quod non ullam deleniti. Nobis sint nemo consequuntur, fugiat. Eius perferendis animi autem incidunt vel quod tenetur nostrum, voluptate omnis quasi quidem illum consequuntur, a, quisquam.</p>
      <p>Laudantium neque magnam vitae nemo quam commodi, in cum dolore obcaecati laborum, excepturi harum, optio qui, consequuntur? Obcaecati dolor sequi nesciunt culpa quia perspiciatis, reiciendis ex debitis, ut tenetur alias.</p>
    </Block>
    <Block >
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni molestiae laudantium dignissimos est nobis delectus nemo ea alias voluptatum architecto, amet similique, saepe iste consectetur in repellat ut minus quibusdam!</p>
      <p>Molestias et distinctio porro nesciunt ratione similique, magni doloribus, rerum nobis, aliquam quae reiciendis quasi modi. Nam a recusandae, fugiat in ea voluptates fuga eius, velit corrupti reprehenderit dignissimos consequatur!</p>
      <p>Blanditiis, cumque quo adipisci. Molestiae, dolores dolorum quos doloremque ipsa ullam eligendi commodi deserunt doloribus inventore magni? Ea mollitia veniam nostrum nihil, iusto doloribus a at! Ea molestiae ullam delectus!</p>
    </Block>
    <Block style={{textAlign: "center"}}>
      {/* The import.meta.env.PACKAGE_VERSION variable gets injected by the vite version plugin. This will need to change when we move to ionic.  */}
      <small>Version {import.meta.env.PACKAGE_VERSION || "x.x.x"}</small>
    </Block>
    
    <div className='imgDecor-AboutPage'></div>
  </PageWrapper>
);

export default AboutPage;


