const image_url_path = "./assets/MotifLineDrawing/other";

export const Other =  [
    {
        name: "eggs",
        sub_category: "",
        image:`${image_url_path}/other_eggs.svg`,
    },
    {
        name: "grubs or larvae",
        sub_category: "",
        image:`${image_url_path}/other_grubs_or_larvae.svg`,
    },
]