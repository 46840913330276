import React, { useState, useEffect } from 'react';

import {
  f7,
  f7ready,
  App,
  Panel,
  Views,
  View,
  Popup,
  Page,
  Navbar,
  Toolbar,
  NavRight,
  Link,
  Block,
  BlockTitle,
  LoginScreen,
  LoginScreenTitle,
  List,
  ListItem,
  ListInput,
  ListButton,
  BlockFooter,
} from 'framework7-react';


import routes from '../js/routes';
import store from '../js/store';
import { OnlineStatusProvider } from "../hooks/useOnlineStatus";
import { AnnotationProvider } from '../hooks/useAnnotation';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { AuthProvider } from "react-oidc-context";
import AuthEvents from '../AuthEvents';
import { WebStorageStateStore } from 'oidc-client-ts';
import { useAuth } from "react-oidc-context";
//import AuthEvents from "../AuthEvents";

const MyApp = () => {
  // Login screen demo data
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Framework7 Parameters
  const f7params = {
    name: 'Digitising the Deep Past', // App name
      theme: 'ios', // Automatic theme detection
      // App store
      store: store,
      // App routes
      routes: routes,
      preloadPreviousPage: false,
      // Register service worker (only on production build)
      serviceWorker: process.env.NODE_ENV ==='production' ? {
        path: '/service-worker.js',
      } : {},
  };

  const alertLoginData = () => {
    f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
      f7.loginScreen.close();
    });
  }

  f7ready(() => {
    // Call F7 APIs here
    
  });

  //Register service worker with VitePWA provided hook
  const postServiceWorker = useRegisterSW({
    onRegistered(registration) {
      console.log('Service Worker Registered', registration, navigator.serviceWorker);
      
    }
  })

  function sendMessage(msg) {
    //send some data from client to the service worker
    if (navigator.serviceWorker.controller) {
      console.log('sending message', msg);
      navigator.serviceWorker.controller.postMessage(msg);
    }
  }

  useEffect(() => {
    //Testing post message to service worker
    sendMessage({ type: "POST_MESSAGE" });
  }, [])

  // useEffect(function windowLocationCheck() {
    
  //   //Match window pathname to tab name, then show the tab
  //   if (window.location.pathname.indexOf("/") > -1 ) {
  //     f7.tab.show(f7.view.home.params.el);
  //   }

  //   if (window.location.pathname.indexOf("collection") > -1) {
  //     console.log('collection page')
  //     f7.tab.show(f7.view.collection.params.el);
  //   }
    
  //   if (window.location.pathname.indexOf("manageCollection") > -1) {
  //     f7.tab.show(f7.view.manageCollection.params.el);
  //   }
    
  //   if (window.location.pathname.indexOf("settings") > -1) {
  //     f7.tab.show(f7.view.settings.params.el);
  //   }

  // }, [window.location.pathname])

  const devEnvironment = process.env.NODE_ENV !== 'production';
  console.log('devenvironement?', devEnvironment)

  const issuer = devEnvironment ? 'http://localhost:8080/realms/rockartdev' : process.env.REACT_APP_AUTH_URL;
  const clientId = devEnvironment ? 'rockart' : process.env.REACT_APP_CLIENT_ID;
  const redirectUri = window.location.origin + '/#/login';
  const logoutRedirectUri = window.location.origin + '/#/login';

  const oidcConfig = {
    authority: window?.__RUNTIME_CONFIG__?.authority,
    client_id: window?.__RUNTIME_CONFIG__?.client_id,
    redirect_uri: window?.__RUNTIME_CONFIG__?.redirect_uri,
    loadUserInfo: true,
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: false,
    accessTokenExpiringNotificationTimeInSeconds: 60
  };

  return (
    <OnlineStatusProvider>
      <AnnotationProvider>
        <AuthProvider {...oidcConfig}>
          <AuthEvents/>
            <AppView { ...f7params } />
        </AuthProvider>
      </AnnotationProvider>
    </OnlineStatusProvider>
  );
}

const AppView = (props) => {
  const auth = useAuth();
  const [authInitialising, setAuthInitialising] = React.useState(true);
  React.useEffect(() => {
    if (authInitialising && !auth.isLoading && !auth.activeNavigator) {
      setAuthInitialising(false);
    }
  }, [auth.isLoading, authInitialising, setAuthInitialising, auth.activeNavigator]);

  return (
    <>
        {authInitialising ? null : (
            <App { ...props } >
                {/* Your main view/tab, should have "view-main" class. It also has "tabActive" prop */}
                <View
                    name="singleView"
                    browserHistory
                    main
                    url="/"
                />
            </App>
        )}
    </>
  )

}
export default MyApp;