import React, {useContext} from 'react';
//import { Page, Navbar, NavRight, Block, Link, Panel, View, f7 } from 'framework7-react';
import Touch from '../components/AnnotationEditor';
import AnnotationEditor from '../components/AnnotationEditor';
import {PageLoadingProvider} from '../hooks/usePageLoading';

import "./AnnotationEditorPage.scss"
import { PageWrapper } from './PageWrapper';

const AnnotationEditorPage = (props) => {
  const { f7route, f7router } = props;

  return (                  
    <PageLoadingProvider>{console.log('f7route', f7route, f7router)}
        <PageWrapper className="AnnotationEditorPage">
           {/* {console.log('page', f7route, props.imageId)} */}
          <AnnotationEditor id={parseInt(props.imageId)} collection_id={parseInt(props.collectionId)} f7route={f7route} />
       </PageWrapper>
    </PageLoadingProvider>
  );
}

export default AnnotationEditorPage;
