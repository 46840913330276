
import HomePage from '../pages/home.jsx';
import AboutPage from '../pages/about.jsx';
import FormPage from '../pages/form.jsx';
import CatalogPage from '../pages/catalog.jsx';
import ProductPage from '../pages/product.jsx';
import SettingsPage from '../pages/settings.jsx';
import AnnotationEditorPage from '../pages/AnnotationEditorPage';
import CollectionsPage from '../pages/CollectionsPage';
import CollectionsImagesPage from '../pages/CollectionsImagesPage.jsx';
import ManageCollectionsPage from '../pages/ManageCollectionsPage.jsx';
import OnlineCollectionsPage from '../pages/OnlineCollectionsPage.jsx';
import LocalCollectionsPage from '../pages/LocalCollectionsPage.jsx';
import TutorialPage from '../pages/TutorialPage';
import DynamicRoutePage from '../pages/dynamic-route.jsx';
import RequestAndLoad from '../pages/request-and-load.jsx';
import NotFoundPage from '../pages/404.jsx';

var routes = [
  {
    path: '/',
    component: HomePage,
    // Temp turn off this option to stop the route issue (URL address bar not update to / when navigate back from other pages.)
    // Ticket number: ESD-567
    // options:{ 
    //   reloadAll: true, //load new page and remove all previous pages from history and DOM
    // }
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/tutorial/',
    component: TutorialPage,
  },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  // {
  //   path: '/catalog/',
  //   component: CatalogPage,
  // },
  {
    path: '/collection/',
    component: CollectionsPage,
  },
  {
    path: '/collection-images/:collectionId',
    component: CollectionsImagesPage,
  },
  {
    path: '/manage-collection/online-collection/',
    component: OnlineCollectionsPage,
  },
  {
    path: '/manage-collection/local-collection/',
    component: LocalCollectionsPage,
  },
  // {
  //   path: '/product/:id/',
  //   component: ProductPage,
  // },
  {
    path: '/annotation/:collectionId/image/:imageId/',
    component: AnnotationEditorPage,
    options:{
      clearPreviousHistory: true, //previous pages history will be cleared after reloading/navigate to the specified route
    }
  },  
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
